import React, { createContext, useContext, useReducer, ReactNode } from 'react'
import {
  controlEarlyLateCheckinReducer,
  SET_EARLY_HOURS,
  SET_EARLY_HOURS_AND_MINUTES,
  SET_EARLY_LATE_HOURS_AND_MINUTES,
  SET_EARLY_MINUTES,
  SET_LATE_HOURS,
  SET_LATE_HOURS_AND_MINUTES,
  SET_LATE_MINUTES
} from '../reducers/control-early-late-checkin.reducer'

export const EARLY = 'EARLY'
export const LATE = 'LATE'
export const MINUTES = 'MINUTES'
export const HOURS = 'HOURS'
export const DEFAULT_DISABLE_MINUTES = 0
export const DEFAULT_ENABLE_MINUTES = 5

type Option = { label: number; id: number }

interface ControlEarlyLateCheckinState {
  hoursOptions: Option[]
  minutesOptions: Option[]
  earlyHoursValue: Option[]
  earlyMinutesValue: Option[]
  lateHoursValue: Option[]
  lateMinutesValue: Option[]
}

interface ControlEarlyLateCheckinContextType {
  state: ControlEarlyLateCheckinState
  actions: {
    setEarlyAndLateHoursAndMinutes: (earlyMinutes: number, lateMinutes: number) => void
    setEarlyOrLateHoursAndMinutes: (hours: number, minutes: number, type: string) => void
    setEarlyHours: (hour: Option[]) => void
    setLateHours: (hour: Option[]) => void
    setEarlyMinutes: (minutes: Option[]) => void
    setLateMinutes: (minutes: Option[]) => void
  }
}

const initialTimeValue = [{ label: 0, id: 0 }]

const initialState: ControlEarlyLateCheckinState = {
  hoursOptions: Array.from({ length: 12 }, (_, x) => ({ label: x, id: x })),
  minutesOptions: Array.from({ length: 11 }, (_, i) => {
    const value = (i + 1) * 5
    return { label: value, id: value }
  }),
  earlyHoursValue: initialTimeValue,
  earlyMinutesValue: initialTimeValue,
  lateHoursValue: initialTimeValue,
  lateMinutesValue: initialTimeValue
}

export const ControlEarlyLateCheckinContext = createContext<
  ControlEarlyLateCheckinContextType | undefined
>(undefined)

export const ControlEarlyLateCheckinProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(controlEarlyLateCheckinReducer, initialState)

  const convertMinutesToSelectValues = (minutes: number) => {
    const hours = Math.floor(minutes / 60)
    const remainingMinutes = minutes % 60
    return {
      hours: [{ id: hours, label: hours }],
      minutes: [{ id: remainingMinutes, label: remainingMinutes }]
    }
  }

  const setEarlyAndLateHoursAndMinutes = (earlyMinutes: number, lateMinutes: number) => {
    const earlyValues = convertMinutesToSelectValues(earlyMinutes)
    const lateValues = convertMinutesToSelectValues(lateMinutes)

    dispatch({
      type: SET_EARLY_LATE_HOURS_AND_MINUTES,
      payload: {
        earlyHours: earlyValues.hours,
        earlyMinutes: earlyValues.minutes,
        lateHours: lateValues.hours,
        lateMinutes: lateValues.minutes
      }
    })
  }

  const setEarlyOrLateHoursAndMinutes = (hours: number, minutes: number, type: string) => {
    dispatch({
      type: type === EARLY ? SET_EARLY_HOURS_AND_MINUTES : SET_LATE_HOURS_AND_MINUTES,
      payload: {
        hours,
        minutes
      }
    })
  }

  const setEarlyHours = (earlyHoursValue: Option[]) => {
    dispatch({
      type: SET_EARLY_HOURS,
      payload: {
        earlyHoursValue
      }
    })
  }

  const setEarlyMinutes = (earlyMinutesValue: Option[]) => {
    dispatch({
      type: SET_EARLY_MINUTES,
      payload: {
        earlyMinutesValue
      }
    })
  }

  const setLateHours = (lateHoursValue: Option[]) => {
    dispatch({
      type: SET_LATE_HOURS,
      payload: {
        lateHoursValue
      }
    })
  }

  const setLateMinutes = (lateMinutesValue: Option[]) => {
    dispatch({
      type: SET_LATE_MINUTES,
      payload: {
        lateMinutesValue
      }
    })
  }

  const actions = {
    setEarlyAndLateHoursAndMinutes,
    setEarlyOrLateHoursAndMinutes,
    setEarlyHours,
    setLateHours,
    setEarlyMinutes,
    setLateMinutes
  }

  return (
    <ControlEarlyLateCheckinContext.Provider value={{ state, actions }}>
      {children}
    </ControlEarlyLateCheckinContext.Provider>
  )
}

export const useControlEarlyLateCheckinContext = (): ControlEarlyLateCheckinContextType => {
  const context = useContext(ControlEarlyLateCheckinContext)
  if (context === undefined) {
    throw new Error(
      'useControlEarlyLateCheckinContext must be used within an ControlEarlyLateCheckinProvider'
    )
  }
  return context
}
