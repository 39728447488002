import CyberSearchBox, { APPOINTMENTS_PAGE } from 'cyber/search/searchbox'
import CyberSearchRefinementList from 'cyber/search/refinement-list'
import CyberSearchPagination from 'cyber/search/pagination'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import { Block } from 'baseui/block'
import { HeadingXSmall } from 'baseui/typography'
import CyberSearch from 'cyber/search/search'
import CyberSearchDateRangePicker from 'cyber/search/date-range-picker'
import { useStyletron } from 'baseui'
import { Configure } from 'react-instantsearch-dom'
import { useTranslation } from 'react-i18next'
import Hits from './hits'
import AppointmentDownloadButton from './appointment-download-button'
import DockTypeRefinementList from './dock-type-refinement-list'
import SchedulerRefinementList from './scheduler-refinement-list'
import Header from 'components/ui/generic/Header'
import React, { useContext, useEffect, useState } from 'react'
import { t } from 'i18next'
import FiltersPopover from 'components/ui/specific/FiltersPopover'
import FilterSummary from './FilterSummary'
import Modals from 'components/shipper/instant-calendar/modals'
import useDefaultFacilities from 'components/shipper/instant-calendar/hooks/use-default-facilities'
import { CurrentUserContext } from 'components/homepage/current-user-context'
import useFacilityInfo from 'components/shipper/instant-calendar/hooks/use-facility-info'
import { FacilitiesContext } from 'components/shipper/instant-calendar/context/facilities-context'
import { facilityService } from 'components/services'

const Filters = ({ dateRange, setDateRange, setAppliedFilters, appliedFilters }) => (
  <FiltersPopover tag={Object.entries(appliedFilters).length}>
    <Block
      backgroundColor="#fff"
      marginTop="10px"
      overrides={{
        Block: {
          style: () => ({
            minWidth: '284px'
          })
        }
      }}>
      <HeadingXSmall margin="0 0 8px">{t('Common.Button.Filters.Text')}</HeadingXSmall>
      <FlexGrid flexGridRowGap="scale300">
        <FlexGridItem>
          <CyberSearchDateRangePicker
            dateRange={dateRange}
            setDateRange={setDateRange}
            setAppliedFilters={setAppliedFilters}
            attribute="arrival_time"
          />
        </FlexGridItem>
        <FlexGridItem>
          <CyberSearchRefinementList
            attribute="facility_name"
            setAppliedFilters={setAppliedFilters}
          />
        </FlexGridItem>
        <FlexGridItem>
          <CyberSearchRefinementList
            attribute="checkin_status"
            setAppliedFilters={setAppliedFilters}
          />
        </FlexGridItem>
        <FlexGridItem>
          <SchedulerRefinementList setAppliedFilters={setAppliedFilters} />
        </FlexGridItem>
        <FlexGridItem>
          <DockTypeRefinementList setAppliedFilters={setAppliedFilters} />
        </FlexGridItem>
        <FlexGridItem>
          <CyberSearchRefinementList attribute="status" setAppliedFilters={setAppliedFilters} />
        </FlexGridItem>
        <FlexGridItem>
          <CyberSearchRefinementList
            attribute="appointment_type_name"
            setAppliedFilters={setAppliedFilters}
          />
        </FlexGridItem>
        <FlexGridItem>
          <CyberSearchRefinementList
            attribute="created_by_name"
            setAppliedFilters={setAppliedFilters}
          />
        </FlexGridItem>
        <FlexGridItem>
          <CyberSearchRefinementList
            attribute="driver_name"
            setAppliedFilters={setAppliedFilters}
          />
        </FlexGridItem>
        <FlexGridItem>
          <AppointmentDownloadButton />
        </FlexGridItem>
      </FlexGrid>
    </Block>
  </FiltersPopover>
)

const AppointmentInstantsearch = () => {
  const [css, theme] = useStyletron()
  const { t } = useTranslation()
  const [numberOfHits, setNumberOfHits] = useState<number>(0)
  const [appliedFilters, setAppliedFilters] = useState({})
  const [dateRange, setDateRange] = useState([])
  const [selectedAppointment, setSelectedAppointment] = useState()
  const { currentUser } = useContext(CurrentUserContext)
  const [selectedFacilities] = useDefaultFacilities(currentUser)
  const { facilities, slotDuration } = useFacilityInfo(selectedFacilities)
  const [facetFacilities, setFacetFacilities] = useState([])

  useEffect(() => {
    facilityService.searchFacility().then(facilities => {
      setFacetFacilities(facilities?.map(facility => facility.id) || [])
    })
  }, [])

  const generateFacetFilters = [`facility_id:${facetFacilities?.join(',')}`]

  return (
    <FacilitiesContext.Provider
      value={{ facilities, slotDuration, selectedDate: new Date(), setSelectedDate: () => {} }}>
      <CyberSearch
        indexName="appointment"
        queryBy="confirmation_id,purchase_order_identifiers"
        numTypos={0}>
        <Modals
          selectedEvent={selectedAppointment}
          setSelectedEvent={setSelectedAppointment}
          mode="editAppointment"
        />
        <Header
          title={t('Appointments.Header.Text')}
          rightItems={[
            <CyberSearchBox collapsable={false} cacheKey={APPOINTMENTS_PAGE} />,
            <Filters
              appliedFilters={appliedFilters}
              dateRange={dateRange}
              setDateRange={setDateRange}
              setAppliedFilters={setAppliedFilters}
            />
          ]}
        />

        <Configure hitsPerPage={25} facetFilters={generateFacetFilters} />
        <Block paddingBottom="scale200" minHeight="60vh" position="relative">
          {Object.keys(appliedFilters).length > 0 && (
            <FilterSummary
              label="appointment"
              numberOfHits={numberOfHits}
              appliedFilters={appliedFilters}
              setDateRange={setDateRange}
            />
          )}
          <Hits setNumberOfHits={setNumberOfHits} setSelectedAppointment={setSelectedAppointment} />
        </Block>
        <div className={css({ paddingTop: theme.sizing.scale200 })}>
          <CyberSearchPagination />
        </div>
      </CyberSearch>
    </FacilitiesContext.Provider>
  )
}

export default AppointmentInstantsearch
