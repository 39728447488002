import React, { Dispatch, ReactElement, useContext, useEffect, useState } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import { List, Plus } from '@phosphor-icons/react'
import { LabelMedium, LabelXSmall } from 'baseui/typography'
import { capitalize } from 'lodash'
import { LABEL_PLACEMENT } from 'baseui/checkbox'
import { Delete } from 'baseui/icon'
import { useStyletron } from 'baseui'
import Button from 'components/ui/generic/Button'
import Checkbox from 'components/ui/generic/Checkbox'
import { AppointmentType } from '../models/AppointmentType'
import ALPHABET from '../constants/excel-columns'

import {
  BulkAppointmentMappingAddColumn,
  BulkAppointmentMappingCancelIcon,
  BulkAppointmentMappingColumn,
  BulkAppointmentMappingColumnContainer,
  BulkAppointmentMappingContainer,
  BulkAppointmentMappingDropList,
  BulkAppointmentMappingFixedFields,
  BulkAppointmentMappingHamburguerIcon,
  BulkAppointmentMappingHeaderContainer,
  BulkAppointmentMappingHeaderSelect
} from './BulkAppointmentMapping.styled'
import { useTranslation } from 'react-i18next'
import { CurrentUserContext } from 'components/homepage/current-user-context'
import Select from 'components/ui/generic/Select'
import { Block } from 'baseui/block'

interface BulkAppointmentMappingProps {
  appointmentType: AppointmentType
  setAppointmentType: Dispatch<AppointmentType>
}

export const CONSTANT_FIELDS = [
  { key: 'arrival_time', label: 'Arrival Time' },
  { key: 'uli', label: 'uli' },
  { key: 'scheduler', label: 'Scheduler' },
  { key: 'equipment_type', label: 'Equip. type' },
  { key: 'trailer_number', label: 'Trailer number' },
  { key: 'trailer_state', label: 'Trailer state' },
  { key: 'trailer_status', label: 'Trailer status' }
]

const BulkAppointmentMapping = ({
  appointmentType,
  setAppointmentType
}: BulkAppointmentMappingProps): ReactElement => {
  const { assignedEquipmentTypes } = useContext(CurrentUserContext)
  const [items, setItems] = useState([])
  const [availableFields, setAvailableFields] = useState([])
  const [availableLetters, setAvailableLetters] = useState([])
  const [css] = useStyletron()
  const { t } = useTranslation()

  useEffect(() => {
    const fields = [
      ...CONSTANT_FIELDS,
      ...appointmentType.questions
        .filter(question => question.id)
        .map(question => ({
          key: question.id,
          label: capitalize(question.prompt)
        }))
    ]

    setAvailableFields(fields)

    const currentMapping = appointmentType?.bulkAppointmentMappingAttributes?.mapping
    let newItems = []
    let remainingLetters = [...ALPHABET]

    if (currentMapping) {
      fields.forEach(field => {
        if (currentMapping[field.key] !== null && currentMapping[field.key] !== undefined) {
          newItems.push({
            id: currentMapping[field.key],
            content: `Column ${ALPHABET[currentMapping[field.key]]}`
          })
          remainingLetters = remainingLetters.filter(
            letter => letter !== ALPHABET[currentMapping[field.key]]
          )
        } else {
          newItems.push({
            id: null,
            content: null
          })
        }
      })

      newItems = newItems.map(item => {
        if (item.id === null) {
          const newItem = {
            id: ALPHABET.indexOf(remainingLetters[0]),
            content: `Column ${remainingLetters[0]}`
          }
          remainingLetters = remainingLetters.filter(letter => letter !== remainingLetters[0])
          return newItem
        }
        return item
      })
    } else {
      const mapping = {}
      fields.forEach((field, index) => {
        newItems.push({
          id: index,
          content: `Column ${ALPHABET[index]}`
        })
        mapping[field.key] = index
      })

      remainingLetters = ALPHABET.slice(fields.length)

      setAppointmentType({
        ...appointmentType,
        bulkAppointmentMappingAttributes: {
          ...appointmentType.bulkAppointmentMappingAttributes,
          mapping
        }
      })
    }

    setItems([
      ...newItems,
      {
        id: ALPHABET.indexOf(remainingLetters[0]),
        content: `Column ${remainingLetters[0]}`
      }
    ])

    setAvailableLetters(remainingLetters.slice(1))
  }, [])

  const addColumn = () => {
    const newItem = {
      id: ALPHABET.indexOf(availableLetters[0]),
      content: `Column ${availableLetters[0]}`
    }

    setItems([...items, newItem])
    setAvailableLetters(availableLetters.slice(1))
  }

  const deleteColumn = (itemIndex: number) => {
    const itemToExclude = items[itemIndex]
    let indexToBeInsertedAt = availableLetters.length - 1

    availableLetters.every((letter, index) => {
      if (itemToExclude.id < ALPHABET.indexOf(letter)) {
        indexToBeInsertedAt = index
        return false
      }
      return true
    })

    const newAvailableLetters = [...availableLetters]

    const firstPartOfArray = newAvailableLetters.slice(0, indexToBeInsertedAt)
    const secondPartOfArray = newAvailableLetters.slice(indexToBeInsertedAt)

    setAvailableLetters([...firstPartOfArray, ALPHABET[itemToExclude.id], ...secondPartOfArray])
    setItems([...items.filter(item => item.id !== itemToExclude.id)])
  }

  const onDragEnd = result => {
    if (!result.destination) {
      return
    }

    const itemSource = items[result.source.index]
    const itemDestination = items[result.destination.index]

    const newItems = [...items]
    newItems[result.source.index] = itemDestination
    newItems[result.destination.index] = itemSource

    setItems(newItems)

    const mapping = {}

    availableFields.forEach((field, index) => {
      mapping[field.key] = newItems[index].id
    })

    setAppointmentType({
      ...appointmentType,
      bulkAppointmentMappingAttributes: {
        ...appointmentType.bulkAppointmentMappingAttributes,
        mapping
      }
    })
  }

  const getSelectedEquipmentType = (equipmentTypeId: string) => {
    return assignedEquipmentTypes?.filter(item => item.id === equipmentTypeId) || []
  }

  return (
    <BulkAppointmentMappingContainer>
      <Block>
        <Checkbox
          checked={appointmentType?.bulkAppointmentMappingAttributes?.headerIncluded}
          onChange={e =>
            setAppointmentType({
              ...appointmentType,
              bulkAppointmentMappingAttributes: {
                ...appointmentType.bulkAppointmentMappingAttributes,
                headerIncluded: e.currentTarget.checked
              }
            })
          }
          overrides={{
            Root: { style: { marginTop: '20px' } }
          }}
          labelPlacement={LABEL_PLACEMENT.right}
          label={t('Settings.AppointmentTypes.CustomQuestions.HeaderIncluded')}
        />
      </Block>
      <BulkAppointmentMappingHeaderContainer>
        <BulkAppointmentMappingHeaderSelect>
          <Select
            options={assignedEquipmentTypes}
            value={getSelectedEquipmentType(
              appointmentType?.bulkAppointmentMappingAttributes?.equipmentTypeId
            )}
            searchable={true}
            clearable={true}
            aria-label="name"
            labelKey="name"
            valueKey="id"
            onChange={async params => {
              setAppointmentType({
                ...appointmentType,
                bulkAppointmentMappingAttributes: {
                  ...appointmentType.bulkAppointmentMappingAttributes,
                  equipmentTypeId: params.option?.id || null
                }
              })
            }}
            label={t('Settings.AppointmentTypes.CustomQuestions.DefaultEquipmentType')}
          />
        </BulkAppointmentMappingHeaderSelect>
        <BulkAppointmentMappingAddColumn>
          <Button disabled={availableLetters.length === 0} onClick={addColumn} IconStart={<Plus />}>
            {t('Settings.AppointmentTypes.CustomQuestions.AddColumn')}
          </Button>
        </BulkAppointmentMappingAddColumn>
      </BulkAppointmentMappingHeaderContainer>
      <BulkAppointmentMappingColumnContainer>
        <BulkAppointmentMappingFixedFields>
          <LabelXSmall marginBottom="scale800">
            {t('Settings.AppointmentTypes.CustomQuestions.AvailableFields')}
          </LabelXSmall>
          {availableFields.map((field, index) => (
            <BulkAppointmentMappingColumn key={`field-${field.id}-${index}`}>
              <LabelXSmall
                marginLeft="scale500"
                overrides={{
                  Block: { style: { opacity: 0.6 } }
                }}>
                {field.label}
              </LabelXSmall>
            </BulkAppointmentMappingColumn>
          ))}
        </BulkAppointmentMappingFixedFields>
        <BulkAppointmentMappingFixedFields>
          <LabelXSmall marginBottom="scale800">
            {t('Settings.AppointmentTypes.CustomQuestions.FileColumn')}
          </LabelXSmall>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {provided => (
                <BulkAppointmentMappingDropList
                  {...provided.droppableProps}
                  ref={provided.innerRef}>
                  {items.map((item, index) => (
                    <Draggable key={item.id} draggableId={`item-${item.id}`} index={index}>
                      {(provided, snapshot) => (
                        <BulkAppointmentMappingColumn
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{ ...provided.draggableProps.style }}
                          isDragging={snapshot.isDragging}>
                          <BulkAppointmentMappingHamburguerIcon>
                            <List />
                          </BulkAppointmentMappingHamburguerIcon>
                          <LabelXSmall
                            marginLeft="scale200"
                            overrides={{
                              Block: { style: { opacity: 0.6 } }
                            }}>
                            {item.content}
                          </LabelXSmall>
                          {index >= availableFields.length && (
                            <BulkAppointmentMappingCancelIcon onClick={() => deleteColumn(index)}>
                              <Delete size={20} />
                            </BulkAppointmentMappingCancelIcon>
                          )}
                        </BulkAppointmentMappingColumn>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </BulkAppointmentMappingDropList>
              )}
            </Droppable>
          </DragDropContext>
        </BulkAppointmentMappingFixedFields>
      </BulkAppointmentMappingColumnContainer>
    </BulkAppointmentMappingContainer>
  )
}

export default BulkAppointmentMapping
