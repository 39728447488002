import React, { useContext, useState } from 'react'
import { Modal, ModalBody, SIZE } from 'baseui/modal'
import {
  FlexContainerFormProps,
  ModalFooterStyled,
  ModalHeaderStyled,
  ModalProps,
  PanelRecurringFormProps,
  SubtitleFlexContainerProps
} from '../create-drop-appointment-modal/create-drop-appointment-modal.styled'
import { useTranslation } from 'react-i18next'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import { INBOUND, OUTBOUND, useAppointmentContext } from 'components/contexts/appointment-context'
import { useFacilityContext } from 'components/contexts/facility-context'
import {
  ArrowLineDown,
  ArrowLineUp,
  BatteryFull,
  Check,
  Truck,
  TrashSimple
} from '@phosphor-icons/react'
import i18n from 'translations/i18n'
import { Block, BlockProps } from 'baseui/block'
import SchedulerEmail from 'components/facilities/steps/fields/scheduler-email'
import { Panel, StatelessAccordion } from 'baseui/accordion'
import RecurringField from '../create-drop-appointment-modal/recurring-field'
import { DateTime } from '../create-drop-appointment-modal/DateTime'
import { CustomQuestionsProvider } from 'components/contexts/custom-questions.context'
import AppointmentForm from '../create-drop-appointment-modal/AppointmentForm'
import FacilitySelect from 'components/facilities/steps/fields/facility-select'
import ButtonGroup from 'components/components/ButtonGroup'
import { DatePicker } from 'baseui/datepicker'
import VButton from 'components/ui/generic/Button'
import CancelButton from '../edit-appointment-modal/cancel-button'
import RescheduleReason from './Reschedule-reason'
import { ErrorMessageButton } from 'components/components/ErrorMessageButton'
import { fancyToast } from 'components/utils'
import { StatusCodes } from 'components/constants/http-status-codes'
import { handlingMethodButtons } from 'components/shared/buttons/liveDropButtons'
import { INBOUND_TYPE, OUTBOUND_TYPE } from 'components/constants/appointment_types'
import { FacilitiesContext } from '../../context/facilities-context'
import SelectDriverUserField from 'components/facilities/steps/fields/SelectDriverUserField'
import { UPDATE } from '../edit-appointment-modal/audit-logs'
import { ConfirmationModal } from 'components/components/ConfirmationModal'
import ConfirmUpdateModal from './ConfirmUpdateModal'

const itemProps: BlockProps = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}

export const APPOINTMENT_TYPE_ALL_OPTION = {
  name: i18n.t('Common.AppointmentTypeToggle.AllOption.Text'),
  id: 0,
  customLabels: {
    purchaseOrderIdentifiers: null,
    purchaseOrderIdentifiersCaption: null,
    scheduler: null,
    schedulerCaption: null
  }
}

const appointmentTypesButtons = [
  {
    label: 'Inbound',
    value: 'inbound',
    icon: <ArrowLineDown size={16} />
  },
  {
    label: 'Outbound',
    value: 'outbound',
    icon: <ArrowLineUp size={16} />
  }
]

const initialAppointment = {
  facilityId: '',
  arrivalTime: new Date(),
  purchaseOrdersAttributes: [],
  appointmentTypeId: '',
  answersAttributes: [],
  equipmentTypeId: '',
  schedulerId: '',
  customerName: '',
  handlingMethod: 'live',
  invoicedDate: null,
  trailer: {
    number: '',
    state: '',
    status: ''
  }
}

const initialAppointmentStates = {
  inbound: initialAppointment,
  outbound: initialAppointment
}

const AppointmentEditForm = ({ isOpen, onClose, onDeleted }) => {
  const [confirmationModal, setConfirmationModal] = useState(false)
  const [appointmentStates, setAppointmentStates] = useState(initialAppointmentStates)
  const { state, actions } = useAppointmentContext()
  const {
    state: { facility }
  } = useFacilityContext()
  const { t } = useTranslation()
  const { reloadOverCapacityAlertToggle, setReloadOverCapacityAlertToggle } = useContext(
    FacilitiesContext
  ) || {
    reloadOverCapacityAlertToggle: false,
    setReloadOverCapacityAlertToggle: (value: boolean) => ''
  }
  const {
    appointments,
    handlingMethod,
    appointmentDirections,
    schedulerEmail,
    driverId,
    loading,
    appointmentId,
    createAppointmentButtonDisableFlags: {
      isCreateAppointmentButtonDisabled: disableUpdateButton,
      errors
    },
    recurring: { showRecurringFields },
    appointmentEdit: { rescheduleReason }
  } = state

  const {
    setHandlingMethod,
    setAppointmentDirection,
    setAppointment,
    setSchedulerEmail,
    updateAppointment,
    getAppointmentIds,
    setRescheduleReason,
    setDriver
  } = actions

  const inbound = appointmentDirections.includes(INBOUND)
  const outbound = appointmentDirections.includes(OUTBOUND)
  const hasHandlingMethod = handlingMethod !== ''
  const columns = inbound && outbound

  const appointmentIds = getAppointmentIds()

  const onUpdateAppointment = async () => {
    const response = await updateAppointment()
    if (response) {
      setReloadOverCapacityAlertToggle?.(!reloadOverCapacityAlertToggle)
      fancyToast(
        {
          info: t('Common.Info.Interpolated.Text', {
            model: t('Common.ModelType.Appointment.Text'),
            action: t('Common.Actions.Updated.Text')
          })
        },
        StatusCodes.CREATED
      )
      setConfirmationModal(state => !state)
      onClose(true)
    }
  }

  const onCloseDeleteAppointment = deleted => {
    if (deleted) {
      onDeleted(true)
    }
  }

  const returnErrors = () => errors.map(error => ({ label: error.label, status: !error.status }))

  return (
    <>
      <Modal
        unstable_ModalBackdropScroll
        onClose={() => {
          setConfirmationModal(state => !state)
        }}
        isOpen={isOpen}
        size={SIZE.auto}
        {...ModalProps}>
        <ModalHeaderStyled>
          {t('Appointments.EditDropAppointmentModal.Header.Title.Text')}
        </ModalHeaderStyled>
        <ModalBody>
          <FlexGrid
            flexGridColumnCount={1}
            flexGridRowGap={'scale600'}
            {...SubtitleFlexContainerProps}>
            <FlexGridItem>
              {t('Appointments.EditDropAppointmentModal.Header.Subtitle.Text')}
            </FlexGridItem>
            <FlexGridItem>
              <small>
                {t('Appointments.EditDropAppointmentModal.Header.RequiredInformation.Text')}
              </small>
            </FlexGridItem>
          </FlexGrid>
          <FlexGrid
            flexGridColumnCount={columns ? [1, 1, 3] : [1, 1, 2]}
            flexGridColumnGap={'scale600'}>
            <FlexGridItem>
              <FlexGrid flexGridColumnCount={1} {...FlexContainerFormProps}>
                <FlexGridItem {...itemProps}>
                  <Block minWidth="90%" padding="scale500">
                    {/* SCHEDULER EMAIL */}
                    <FlexGridItem {...itemProps}>
                      <Block minWidth="100%">
                        <SchedulerEmail
                          schedulerEmail={schedulerEmail}
                          setSchedulerEmail={setSchedulerEmail}
                        />
                      </Block>
                    </FlexGridItem>

                    {/* FACILITY SELECT */}
                    <FlexGridItem {...itemProps}>
                      <Block minWidth="100%">
                        <FacilitySelect disabled={appointmentId ? true : false} />
                      </Block>
                    </FlexGridItem>
                    {/** HANDLING METHOD BUTTON GROUP */}
                    <ButtonGroup
                      mode="radio"
                      label={t(
                        'Appointments.EditDropAppointmentModal.Fields.HandlingMethod.Label.Text'
                      )}
                      buttons={handlingMethodButtons(facility)}
                      value={handlingMethod}
                      onChange={setHandlingMethod}
                    />
                    {/** APPOINTMENT TYPE */}
                    {handlingMethod === 'drop' && (
                      <ButtonGroup
                        mode="checkbox"
                        label={t(
                          'Appointments.EditDropAppointmentModal.Fields.AppointmentCategory.Label.Text'
                        )}
                        buttons={appointmentTypesButtons}
                        value={appointmentDirections}
                        onChange={setAppointmentDirection}
                        visibility={handlingMethod === 'drop'}
                      />
                    )}
                    <FlexGrid flexGridRowGap={'scale600'}>
                      <FlexGridItem>
                        <DateTime />
                      </FlexGridItem>
                      {showRecurringFields && (
                        <FlexGridItem>
                          <StatelessAccordion expanded={showRecurringFields ? ['1'] : []}>
                            <Panel {...PanelRecurringFormProps} key={'1'}>
                              <RecurringField />
                            </Panel>
                          </StatelessAccordion>
                        </FlexGridItem>
                      )}
                      <FlexGridItem {...itemProps}>
                        <Block minWidth="100%">
                          <SelectDriverUserField
                            setSelectedDriver={setDriver}
                            selectedDriver={driverId}
                          />
                        </Block>
                      </FlexGridItem>
                      <FlexGridItem>
                        <RescheduleReason
                          value={rescheduleReason}
                          onChange={value => {
                            setRescheduleReason(value)
                          }}
                        />
                      </FlexGridItem>
                    </FlexGrid>
                  </Block>
                </FlexGridItem>
              </FlexGrid>
            </FlexGridItem>
            {hasHandlingMethod && (inbound || (!inbound && !outbound)) && (
              <FlexGridItem>
                <Block minWidth="90%">
                  <CustomQuestionsProvider>
                    <AppointmentForm
                      appointmentDirection={INBOUND_TYPE}
                      appointment={appointments[INBOUND]}
                      facility={facility}
                      setAppointment={(field, value) => setAppointment({ [field]: value }, INBOUND)}
                    />
                  </CustomQuestionsProvider>
                </Block>
              </FlexGridItem>
            )}
            {hasHandlingMethod && outbound && (
              <FlexGridItem>
                <Block minWidth="90%">
                  <CustomQuestionsProvider>
                    <AppointmentForm
                      appointmentDirection={OUTBOUND_TYPE}
                      appointment={appointments[OUTBOUND]}
                      facility={facility}
                      setAppointment={(field, value) =>
                        setAppointment({ [field]: value }, OUTBOUND)
                      }
                    />
                  </CustomQuestionsProvider>
                </Block>
              </FlexGridItem>
            )}
          </FlexGrid>
        </ModalBody>
        <ModalFooterStyled>
          <Block width={'100%'} justifyContent={'space-between'} display={'flex'}>
            <Block>
              <CancelButton
                appointmentIds={appointmentIds}
                close={onCloseDeleteAppointment}
                buttonProps={{ startEnhancer: <TrashSimple size={16} /> }}
                appointment={appointments.inbound}
                appointments={appointments}
                facility={facility}
                label={t('Appointments.EditDropAppointmentModal.Actions.Delete.Text')}
              />
            </Block>
            <Block justifyContent={'flex-end'} display={'flex'}>
              <ErrorMessageButton
                label={t('Appointments.EditDropAppointmentModal.Actions.Close.Text')}
                errors={[]}
                kind="secondary"
                onClick={onClose}
              />

              <Block marginRight="scale300" />
              <ErrorMessageButton
                kind="primary"
                startEnhancer={<Check size={16} />}
                errors={returnErrors()}
                onClick={onUpdateAppointment}
                isLoading={loading}
                statefulTooltipProps={{ placement: 'top' }}
                label={t('Appointments.EditDropAppointmentModal.Actions.Create.Text')}
              />
            </Block>
          </Block>
        </ModalFooterStyled>
      </Modal>
      <ConfirmUpdateModal
        isOpen={confirmationModal}
        loading={loading}
        errors={returnErrors()}
        title={t('Appointments.EditDropAppointmentModal.Actions.ConfirmUpdateModal.Text')}
        cancelAction={onClose}
        confirmAction={() => {
          onUpdateAppointment()
        }}
      />
    </>
  )
}

export default AppointmentEditForm
