import { styled } from 'baseui'

export const FormDivider = styled('hr', ({ $theme }) => ({
  ...$theme.borders.border100,
  borderWidth: '0 0 1px',
  margin: `${$theme.sizing.scale1000} 0`
}))

export const Flex = styled('div', () => ({
  display: 'flex'
}))
