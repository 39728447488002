import React, { useEffect } from 'react'
import Select from 'components/ui/generic/Select'
import { useAppointmentContext, IS_DROP } from 'components/contexts/appointment-context'
import { useTranslation } from 'react-i18next'
import FormControl from 'components/ui/generic/FormControl'

const AppointmentTypeSelect = ({
  appointmentDirection,
  onChange,
  appointmentTypes,
  disabled = false
}) => {
  const { state } = useAppointmentContext()
  const { handlingMethod, appointments, fromOrder } = state
  const { t } = useTranslation()
  // Getting the specific appointment state based on the direction (e.g., 'inbound' or 'outbound')
  const directionAppointmentType = appointments[appointmentDirection.toLowerCase()]

  // Determine if the handling method is 'drop'
  const isDrop = handlingMethod === IS_DROP

  // Filter or list all options based on handlingMethod and appointment direction type
  const categoryAppointmentTypes = appointmentTypes?.reduce((filtered, appointmentType, index) => {
    // Include all types if not 'drop' or if 'drop' and types match the directionAppointmentType

    if (!isDrop || (isDrop && appointmentType.type === appointmentDirection)) {
      filtered.push({ id: appointmentType.id, label: appointmentType.name, index: index })
    }
    return filtered
  }, [])

  useEffect(() => {
    if (
      !directionAppointmentType.appointmentTypeId &&
      fromOrder &&
      categoryAppointmentTypes?.length > 0
    ) {
      onChange(categoryAppointmentTypes[0].id && { option: categoryAppointmentTypes[0] })
    }
  }, [categoryAppointmentTypes])

  const value =
    categoryAppointmentTypes?.find(
      appointmentType => appointmentType.id === directionAppointmentType.appointmentTypeId
    ) || {}

  return (
    <>
      {categoryAppointmentTypes ? (
        <FormControl>
          <Select
            disabled={disabled}
            options={categoryAppointmentTypes}
            label={t('Appointments.CreateAppointmentModal.Fields.AppointmentType.Label.Text')}
            value={[value]}
            searchable={false}
            clearable={false}
            onChange={onChange}
            aria-label="AppointmentTypeSelect"
          />
        </FormControl>
      ) : (
        ''
      )}
    </>
  )
}

export default AppointmentTypeSelect
