export const SET_EARLY_LATE_HOURS_AND_MINUTES = 'SET_EARLY_LATE_HOURS_AND_MINUTES'
export const SET_LATE_HOURS_AND_MINUTES = 'SET_LATE_HOURS_AND_MINUTES'
export const SET_EARLY_HOURS_AND_MINUTES = 'SET_EARLY_HOURS_AND_MINUTES'
export const SET_EARLY_HOURS = 'SET_EARLY_HOURS'
export const SET_EARLY_MINUTES = 'SET_EARLY_MINUTES'
export const SET_LATE_HOURS = 'SET_LATE_HOURS'
export const SET_LATE_MINUTES = 'SET_LATE_MINUTES'

export const controlEarlyLateCheckinReducer = (state, action) => {
  switch (action.type) {
    case SET_EARLY_LATE_HOURS_AND_MINUTES: {
      const { earlyHours, earlyMinutes, lateHours, lateMinutes } = action.payload

      return {
        ...state,
        earlyHoursValue: earlyHours,
        earlyMinutesValue: earlyMinutes,
        lateHoursValue: lateHours,
        lateMinutesValue: lateMinutes
      }
    }
    case SET_EARLY_HOURS_AND_MINUTES: {
      const { hours, minutes } = action.payload

      return {
        ...state,
        earlyHoursValue: [{ id: hours, label: hours }],
        earlyMinutesValue: [{ id: minutes, label: minutes }]
      }
    }
    case SET_LATE_HOURS_AND_MINUTES: {
      const { hours, minutes } = action.payload

      return {
        ...state,
        lateHoursValue: [{ id: hours, label: hours }],
        lateMinutesValue: [{ id: minutes, label: minutes }]
      }
    }
    case SET_EARLY_HOURS: {
      const { earlyHoursValue } = action.payload
      return {
        ...state,
        earlyHoursValue
      }
    }
    case SET_EARLY_MINUTES: {
      const { earlyMinutesValue } = action.payload
      return {
        ...state,
        earlyMinutesValue
      }
    }
    case SET_LATE_HOURS: {
      const { lateHoursValue } = action.payload
      return {
        ...state,
        lateHoursValue
      }
    }
    case SET_LATE_MINUTES: {
      const { lateMinutesValue } = action.payload
      return {
        ...state,
        lateMinutesValue
      }
    }
    default:
      return state
  }
}
