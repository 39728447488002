import { Facility } from './Facility'
import { Model } from './Model'
import { Scheduler } from './Scheduler'

export interface Order extends Model {
  id?: string
  facilitiesId?: string
  facility?: Facility
  number?: string
  identifier?: string
  orderType?: string
  priority?: string
  startDatePeriod?: Date
  endDatePeriod?: Date
  status?: string
  schedulerId?: string
  carrierId?: string
  scheduler?: Scheduler
  products?: string
  quantity?: number
  deliveryInstructions?: string
  loadId?: string
  customerPo?: string
  quantityUomId?: string
  weightUomId?: string
  weight?: number
  quantityUom?: {
    category: string
    name: string
  }
  weightUom?: {
    category: string
    name: string
  }
  carrier?: {
    name: string
  }
  customFieldOne?: string
  customFieldTwo?: string
}

export const PURCHASE = 'purchase'
export const SALES = 'sales'
export const RECEIVING = 'receiving'

export const INBOUND_ORDER_TYPE = [PURCHASE, RECEIVING]
export const OUTBOUND_ORDER_TYPE = [SALES]
