import React, { useContext, useEffect, useState } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'baseui/modal'
import CustomPanel from './custom-panel'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import Tags from './Tags'
import Button from 'components/ui/specific/PrimaryButton'
import ErrorButton from 'components/ui/specific/ErrorButton'
import { PencilSimple, X } from '@phosphor-icons/react'
import { KIND } from 'baseui/button'
import EventIcon from 'components/ui/specific/EventIcon'
import { useStyletron } from 'baseui'
import useDropAppointments from '../../hooks/use-drop-appointments'
import moment from 'moment'
import Milestones from './../edit-appointment-modal/MilestonesSection'
import AuditLogs from './../edit-appointment-modal/audit-logs'
import { useTranslation } from 'react-i18next'
import Tabs from 'components/ui/generic/Tabs'
import { Tab } from 'baseui/tabs'
import {
  FlexGridFooterProps,
  FlexGridHeaderProps,
  FlexGridHeaderSubtitleProps,
  FlexGridPanelContentProps,
  IconEquipmentTypeDiv,
  ModalDropProps,
  TabPanelProps,
  TextContainer,
  TextLine,
  itemProps
} from './edit-drop-appointment-modal.styled'
import AppointmentEditForm from './AppointmentEditForm'
import { useAppointmentContext } from 'components/contexts/appointment-context'
import { SpinnerWrapper } from 'components/pages/UserRoles/UserRolesTable.styled'
import StyledSpinner from 'components/shared/styled-spinner'
import { DROP, LIVE } from 'components/constants/handling_method'
import { CurrentUserContext } from 'components/homepage/current-user-context'
import { ConfirmationModal } from 'components/components/ConfirmationModal'

const EditDropAppointment = (props: ModalProps) => {
  const { selectedEvent, isOpen, close } = props
  const { currentUser } = useContext(CurrentUserContext)
  const [shouldReload, setShouldReload] = useState(false)
  const [confirmationModal, setConfirmationModal] = useState({
    state: false,
    approved: false,
    loading: false
  })
  const { appointment, appointmentData, loading } = useDropAppointments(
    selectedEvent.id,
    shouldReload
  )
  const {
    actions: {
      setEditAppointment,
      loadAppointmentData,
      resetAppointmentStates,
      changeApprovalWorflow
    }
  } = useAppointmentContext()
  const [activeTab, setActiveTab] = useState<string>('0')
  const [css, theme] = useStyletron()
  const { t } = useTranslation()
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const isRequested = appointment?.isRequestedAppointment
  const onEditAppointment = () => {
    setEditAppointment(selectedEvent.id)
    setIsEditModalOpen(true)
  }

  useEffect(() => {
    loadAppointmentData(appointmentData)
  }, [appointmentData])

  const onCloseEditModal = reload => {
    setIsEditModalOpen(false)
    reload && setShouldReload(prev => !prev)
  }

  const onDeleted = () => {
    resetAppointmentStates()
    close()
  }

  const onCloseShowModal = () => {
    resetAppointmentStates()
    close()
  }

  const setApproval = async (approved: boolean) => {
    setConfirmationModal({
      ...confirmationModal,
      loading: true
    })
    const resp = await changeApprovalWorflow(approved)
    if (resp) {
      setShouldReload(prev => !prev)
    }
    setConfirmationModal({
      ...confirmationModal,
      state: false,
      approved: false,
      loading: false
    })
    onCloseShowModal()
  }

  if (!appointment) {
    return <></>
  }

  return (
    <>
      <Modal
        size="auto"
        unstable_ModalBackdropScroll
        onClose={onCloseShowModal}
        isOpen={isOpen}
        {...ModalDropProps}>
        <ModalHeader>{appointment.confirmationId}</ModalHeader>
        <ModalBody>
          {loading ? (
            <SpinnerWrapper>
              <StyledSpinner />
            </SpinnerWrapper>
          ) : (
            <>
              <FlexGrid
                flexGridColumnCount={3}
                flexGridColumnGap="scale900"
                {...FlexGridHeaderProps}>
                <FlexGridItem {...FlexGridHeaderSubtitleProps}>
                  <div>
                    <IconEquipmentTypeDiv>
                      <EventIcon
                        event={{
                          extendedProps: {
                            equipmentTypeId: appointment.equipmentTypeId,
                            daysOfWeek: false
                          }
                        }}
                        hideColor
                      />
                    </IconEquipmentTypeDiv>
                    <span
                      className={css({
                        fontSize: theme.typography.LabelMedium.fontSize,
                        fontWeight: theme.typography.HeadingMedium.fontWeight
                      })}>
                      {appointment.schedulerName}
                    </span>
                  </div>
                  <div>
                    <span data-testid={'show-appointment-header-date'}>
                      <small>{appointment.headerDate}</small>
                    </span>
                  </div>
                </FlexGridItem>
                <FlexGridItem display="none"></FlexGridItem>
              </FlexGrid>

              <AppointmentSection appointment={appointment} />

              {appointment.handlingMethod === DROP && appointment.dropInfo && (
                <AppointmentTrailerSection appointment={appointment.dropInfo} />
              )}

              {appointment.handlingMethod === DROP && appointment.relatedAppointmentDropInfo && (
                <AppointmentTrailerSection appointment={appointment.relatedAppointmentDropInfo} />
              )}

              <CustomPanel
                title={t('Appointments.ShowAppointmentModal.Milestone.Header.Text')}
                tag={appointment.status}>
                <Tabs
                  onChange={({ activeKey }) => {
                    setActiveTab(activeKey as any)
                  }}
                  activeKey={activeTab}>
                  <Tab
                    {...TabPanelProps}
                    title={t('Appointments.EditAppointmentModal.Milestones.Header.Text')}>
                    <Milestones appointment={appointment} setAppointment={e => {}} />
                  </Tab>
                  <Tab
                    {...TabPanelProps}
                    title={t('Appointments.EditAppointmentModal.AuditLogs.Header.Text')}>
                    <AuditLogs appointment={appointment} isShowDropAppointment />
                  </Tab>
                </Tabs>
              </CustomPanel>
            </>
          )}
        </ModalBody>
        {!(currentUser?.viewOnly || isRequested) && (
          <ModalFooter>
            <FlexGrid flexGridColumnGap="scale900" {...FlexGridFooterProps}>
              <Button onClick={onCloseShowModal} kind={KIND.secondary} IconStart={<X />}>
                {t('Appointments.ShowAppointmentModal.Actions.Cancel.Text')}
              </Button>
              <Button onClick={onEditAppointment} IconStart={<PencilSimple />}>
                {t('Appointments.ShowAppointmentModal.Actions.Edit.Text')}
              </Button>
            </FlexGrid>
          </ModalFooter>
        )}
        {isRequested && (
          <ModalFooter>
            <FlexGrid flexGridColumnGap="scale900" {...FlexGridFooterProps}>
              <Button
                onClick={() => {
                  setConfirmationModal({
                    state: true,
                    approved: true,
                    loading: false
                  })
                }}>
                {t('Appointments.ShowAppointmentModal.Actions.Approve.Text')}
              </Button>
              <ErrorButton
                onClick={() => {
                  setConfirmationModal({
                    state: true,
                    approved: false,
                    loading: false
                  })
                }}
                kind={KIND.secondary}>
                {t('Appointments.ShowAppointmentModal.Actions.Decline.Text')}
              </ErrorButton>
            </FlexGrid>
          </ModalFooter>
        )}
      </Modal>
      {isEditModalOpen && (
        <AppointmentEditForm
          onClose={onCloseEditModal}
          isOpen={isEditModalOpen}
          onDeleted={onDeleted}
        />
      )}
      <ConfirmationModal
        isOpen={confirmationModal.state}
        loading={confirmationModal.loading}
        title={t('Appointments.ShowAppointmentModal.Actions.ConfirmationMessage.Text', {
          state: confirmationModal.approved
            ? t('Appointments.ShowAppointmentModal.Actions.Approve.Text')
            : t('Appointments.ShowAppointmentModal.Actions.Decline.Text')
        })}
        cancelAction={() => {
          setConfirmationModal({ state: false, approved: false, loading: false })
        }}
        confirmAction={() => {
          setApproval(confirmationModal.approved)
        }}
      />
    </>
  )
}

const AppointmentSection = ({ appointment }) => {
  const { t } = useTranslation()

  return (
    <CustomPanel
      initialState={{
        expanded: true
      }}
      title={t('Appointments.ShowAppointmentModal.PanelsAppointmentDetails.Header.Text')}>
      <FlexGrid flexGridColumnCount={2} flexGridColumnGap="scale900" {...FlexGridPanelContentProps}>
        <FlexGridItem {...itemProps}>
          <TextContainer>
            <TextLine>
              <small>
                {t('Appointments.ShowAppointmentModal.PanelsAppointmentDetails.ArrivalDate.Text')}
              </small>
            </TextLine>
            <TextLine>{moment(appointment.arrivalTime).format('MM/DD/YYYY')}</TextLine>
          </TextContainer>
        </FlexGridItem>
        <FlexGridItem {...itemProps}>
          <TextContainer>
            <TextLine>
              <small>
                {t('Appointments.ShowAppointmentModal.PanelsAppointmentDetails.ArrivalTime.Text')}
              </small>
            </TextLine>
            <TextLine>{moment(appointment.arrivalTime).format('h:mm a')}</TextLine>
          </TextContainer>
        </FlexGridItem>
        <FlexGridItem {...itemProps}>
          <TextContainer>
            <TextLine>
              <small>
                {t('Appointments.ShowAppointmentModal.PanelsAppointmentDetails.Facility.Text')}
              </small>
            </TextLine>
            <TextLine>{appointment.facilityName}</TextLine>
          </TextContainer>
        </FlexGridItem>
        <FlexGridItem {...itemProps}>
          <TextContainer>
            <TextLine>
              <small>
                {t('Appointments.ShowAppointmentModal.PanelsAppointmentDetails.Scheduler.Text')}
              </small>
            </TextLine>
            <TextLine>{appointment.schedulerName}</TextLine>
          </TextContainer>
        </FlexGridItem>
        <FlexGridItem {...itemProps}>
          <TextContainer>
            <TextLine>
              <small>
                {t('Appointments.ShowAppointmentModal.PanelsAppointmentDetails.Handling.Text')}
              </small>
            </TextLine>
            <TextLine>{appointment.handlingMethod}</TextLine>
          </TextContainer>
        </FlexGridItem>
        {appointment.carrierName && (
          <FlexGridItem {...itemProps}>
            <TextContainer>
              <TextLine>
                <small>
                  {t('Appointments.ShowAppointmentModal.PanelsAppointmentDetails.Carrier.Text')}
                </small>
              </TextLine>
              <TextLine>{appointment.carrierName}</TextLine>
            </TextContainer>
          </FlexGridItem>
        )}
        {appointment.handlingMethod === DROP && (
          <FlexGridItem {...itemProps}>
            <TextContainer>
              <TextLine>
                <small>
                  {t(
                    'Appointments.ShowAppointmentModal.PanelsAppointmentDetails.TypeOfAppointment.Text'
                  )}
                </small>
              </TextLine>
              <TextLine>{appointment.appointmentType}</TextLine>
            </TextContainer>
          </FlexGridItem>
        )}
        {appointment.handlingMethod === LIVE && (
          <>
            <FlexGridItem key={1} {...itemProps}>
              <TextContainer>
                <TextLine>
                  <small>
                    {t('Appointments.ShowAppointmentModal.TrailerDetails.EquipmentType.Label')}
                  </small>
                </TextLine>
                <TextLine>{appointment.equipmentTypeName}</TextLine>
              </TextContainer>
            </FlexGridItem>
            <FlexGridItem key={2} {...itemProps}>
              <TextContainer>
                <TextLine>
                  <small>
                    {t('Appointments.ShowAppointmentModal.TrailerDetails.shipmentId.Label')}
                  </small>
                </TextLine>
                <TextLine>
                  <Tags tags={appointment.purchaseOrdersAttributes} />
                </TextLine>
              </TextContainer>
            </FlexGridItem>
          </>
        )}
        {appointment.driverName && (
          <FlexGridItem {...itemProps}>
            <TextContainer>
              <TextLine>
                <small>
                  {t('Appointments.ShowAppointmentModal.PanelsAppointmentDetails.Driver.Text')}
                </small>
              </TextLine>
              <TextLine>{appointment.driverName}</TextLine>
            </TextContainer>
          </FlexGridItem>
        )}
        {appointment?.dockName && (
          <FlexGridItem {...itemProps}>
            <TextContainer>
              <TextLine>
                <small>
                  {t('Appointments.ShowAppointmentModal.PanelsAppointmentDetails.DockName.Text')}
                </small>
              </TextLine>
              <TextLine>{appointment?.dockName}</TextLine>
            </TextContainer>
          </FlexGridItem>
        )}
        {appointment?.dockTime && (
          <FlexGridItem {...itemProps}>
            <TextContainer>
              <TextLine>
                <small>
                  {t('Appointments.ShowAppointmentModal.PanelsAppointmentDetails.DockDate.Text')}
                </small>
              </TextLine>
              <TextLine>{moment(appointment?.dockTime).format('MM/DD/YYYY')}</TextLine>
            </TextContainer>
          </FlexGridItem>
        )}
        {appointment?.dockTime && (
          <FlexGridItem {...itemProps}>
            <TextContainer>
              <TextLine>
                <small>
                  {t('Appointments.ShowAppointmentModal.PanelsAppointmentDetails.DockTime.Text')}
                </small>
              </TextLine>
              <TextLine>{moment(appointment?.dockTime).format('h:mm a')}</TextLine>
            </TextContainer>
          </FlexGridItem>
        )}
      </FlexGrid>
    </CustomPanel>
  )
}

const AppointmentTrailerSection = ({ appointment }) => {
  const { t } = useTranslation()

  return (
    <CustomPanel
      title={t('Appointments.ShowAppointmentModal.TrailerDetails.Header.Text', {
        type: appointment?.appointmentType
      })}>
      <FlexGrid flexGridColumnCount={2} flexGridColumnGap="scale900" {...FlexGridPanelContentProps}>
        <FlexGridItem {...itemProps}>
          <TextContainer>
            <TextLine>
              <small>
                {t('Appointments.ShowAppointmentModal.TrailerDetails.EquipmentType.Text', {
                  type: appointment?.appointmentType
                })}
              </small>
            </TextLine>
            <TextLine>{appointment?.equipmentType}</TextLine>
          </TextContainer>
        </FlexGridItem>
        <FlexGridItem {...itemProps}>
          <TextContainer>
            <TextLine>
              <small>
                {t('Appointments.ShowAppointmentModal.TrailerDetails.TrailerStatus.Text', {
                  type: appointment?.appointmentType
                })}
              </small>
            </TextLine>
            <TextLine>{appointment?.trailer?.status}</TextLine>
          </TextContainer>
        </FlexGridItem>
        <FlexGridItem {...itemProps}>
          <TextContainer>
            <TextLine>
              <small>
                {' '}
                {t('Appointments.ShowAppointmentModal.TrailerDetails.Trailer.Text', {
                  type: appointment?.appointmentType
                })}
              </small>
            </TextLine>
            <TextLine>{appointment?.trailer?.number}</TextLine>
          </TextContainer>
        </FlexGridItem>
        <FlexGridItem {...itemProps}>
          <TextContainer>
            <TextLine>
              <small>
                {t('Appointments.ShowAppointmentModal.TrailerDetails.shipmentId.Text', {
                  type: appointment?.appointmentType
                })}
              </small>
            </TextLine>
            <TextLine>
              <Tags tags={appointment?.purchaseOrdersAttributes} />
            </TextLine>
          </TextContainer>
        </FlexGridItem>
        <FlexGridItem {...itemProps}>
          <TextContainer>
            <TextLine>
              <small>
                {t('Appointments.ShowAppointmentModal.TrailerDetails.TrailerState.Text', {
                  type: appointment?.appointmentType
                })}
              </small>
            </TextLine>
            <TextLine>{appointment?.trailer?.state}</TextLine>
          </TextContainer>
        </FlexGridItem>
      </FlexGrid>
    </CustomPanel>
  )
}

export default EditDropAppointment
