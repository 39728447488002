import { Modal, ModalHeader, ModalBody, ModalFooter } from 'baseui/modal'
import DeleteButton from 'components/shared/fields/delete-button'
import TwilioPhoneNumber from 'components/shared/fields/twilio-phone-number'
import { User } from 'components/users'
import { fancyToast } from 'components/utils'
import authenticatedFetch from 'components/utils/authenticated-fetch'
import CyberInput from 'cyber/input'
import React, { useContext, useEffect, useState } from 'react'
import { connectSearchBox } from 'react-instantsearch-dom'
import { SelectSearch } from 'components/components/SelectSearch'
import { CurrentUserContext } from 'components/homepage/current-user-context'
import Select from 'components/ui/generic/Select'
import Button from 'components/ui/specific/PrimaryButton'
import Checkbox from 'components/ui/generic/Checkbox'
import FormControl from 'components/ui/generic/FormControl'
import { capitalize } from 'lodash'
import { useTranslation } from 'react-i18next'
import { DEFAULT_ROLE_NAMES, INTERNAL, YARD_JOCKEY_AUDIENCE, DRIVER } from '../../models/Role'
import { useUserRolesContext } from '../../contexts/user-roles.context'
import { validateUser } from 'components/utils/user-validations'
import { ConfirmationModal } from 'components/components/ConfirmationModal'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import ErrorButton from 'components/ui/specific/ErrorButton'
import { getUserTypeForInternals } from '../../utils/get-user-type-for-internals'
import { DatePicker } from 'baseui/datepicker'
import TimePicker from 'components/ui/generic/TimePicker'
import Input from 'components/ui/generic/Input'
import { EXTERNAL_ID_MAX_LENGTH } from '../modals/InviteShipperModal'

export const ShipperModal = ({ userId, setUserId, refine }) => {
  const [user, setUser] = useState<User>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const { currentUser } = useContext(CurrentUserContext)
  const [selectedInternalUserRole, setSelectedInternalUserRole] = useState<any>([])
  const [internalUsersRoles, setInternalUsersRoles] = useState<any>([])
  const [openConfirmModal, setOpenConfirmModal] = useState(false)
  const { t } = useTranslation()

  const {
    roles: { items },
    actions: { search }
  } = useUserRolesContext()

  useEffect(() => {
    search(undefined, [INTERNAL, YARD_JOCKEY_AUDIENCE, DRIVER])
  }, [])

  useEffect(() => {
    if (userId) {
      authenticatedFetch({ path: `/users/${userId}.json` }).then(([json, status]) => {
        if ([200, 304].includes(status)) {
          setUser(json)
          setLoading(false)
          const role = items?.filter(i => i.id === json.user_role_id)[0]
          if (role) {
            setSelectedInternalUserRole([
              {
                id: role.id,
                label: capitalize(role.name)
              }
            ])
          }
        }
      })
    }
  }, [userId])

  useEffect(() => {
    if (items && items.length) {
      setInternalUsersRoles(
        items.map(item => {
          return {
            label: DEFAULT_ROLE_NAMES.includes(item.name) ? capitalize(item.name) : item.name,
            id: item.id,
            userType: getUserTypeForInternals(item.audience)
          }
        })
      )
    }
  }, [items])

  const onClose = () => {
    setUser(null)
    setUserId(null)
    setSelectedInternalUserRole([])
    refine()
  }

  const onInactivate = async () => {
    setLoading(true)
    const [json, status] = await authenticatedFetch({
      path: `/users/${userId}/update_status_user.json`,
      method: 'PATCH',
      body: {
        user: {
          disabled: true
        }
      }
    })
    if (status == 204) {
      fancyToast(
        { info: t('Settings.InternalUsers.ShipperModal.Alerts.UserAccessRevoked.Text') },
        status
      )
      onClose()
    } else {
      fancyToast(json, status)
    }
    setLoading(false)
  }

  const onActivate = async () => {
    setLoading(true)
    const [json, status] = await authenticatedFetch({
      path: `/users/${userId}/update_status_user.json`,
      method: 'PATCH',
      body: {
        user: {
          disabled: false
        }
      }
    })
    if (status == 204) {
      fancyToast(
        { info: t('Settings.InternalUsers.ShipperModal.Alerts.UserAccessGranted.Text') },
        status
      )
      onClose()
    } else {
      fancyToast(json, status)
    }
    setLoading(false)
  }

  const onRemove = async () => {
    setLoading(true)
    const [json, status] = await authenticatedFetch({
      path: `/users/${userId}.json`,
      method: 'DELETE'
    })
    if (status == 204) {
      fancyToast(
        { info: t('Settings.InternalUsers.ShipperModal.Alerts.UserAccessRemoved.Text') },
        status
      )
      onClose()
    } else {
      fancyToast(json, status)
    }
    setLoading(false)
  }

  const saveModal = async () => {
    setLoading(true)
    const [json, status] = await authenticatedFetch({
      path: `/users/${user.id}.json`,
      method: 'PATCH',
      body: { user }
    })

    setLoading(false)

    if (status === 200) {
      fancyToast({ info: t('Settings.InternalUsers.ShipperModal.Alerts.UserUpdated.Text') }, status)
      onClose()
    } else {
      fancyToast(json, status)
    }
  }

  const onChangeRole = option => {
    setUser({
      ...user,
      userRoleId: option.id as string,
      userType: option.userType,
      user_type: option.userType
    })
    setSelectedInternalUserRole([option])
  }

  const getRoleValue = () =>
    selectedInternalUserRole.length
      ? selectedInternalUserRole
      : [
          {
            id: user?.userRoleId
          }
        ]

  return (
    <>
      <Modal isOpen={!!user} unstable_ModalBackdropScroll onClose={onClose}>
        <ModalHeader>{t('Settings.InternalUsers.ShipperModal.Header.Text')}</ModalHeader>

        <ModalBody>
          <FormControl label={t('Settings.InternalUsers.ShipperModal.Fields.Role.Label.Text')}>
            <Select
              clearable={false}
              backspaceClearsInputValue
              maxDropdownHeight="250px"
              deleteRemoves
              value={getRoleValue()}
              options={internalUsersRoles}
              onChange={({ option }) => onChangeRole(option)}
            />
          </FormControl>
          <FormControl label={t('Settings.InternalUsers.ShipperModal.Fields.Name.Label.Text')}>
            <CyberInput
              name="user[name]"
              value={user?.name}
              disabled={loading}
              onChange={e => {
                setUser({
                  ...user,
                  name: e.currentTarget.value
                })
              }}
            />
          </FormControl>
          {![DRIVER].includes(user?.user_type) && (
            <FormControl
              label={t('Settings.InternalUsers.ShipperModal.Fields.EmailAddress.Label.Text')}>
              <CyberInput
                autoComplete="off"
                disabled
                name="user[email_address]"
                value={user?.emailAddress || user?.email_address}
              />
            </FormControl>
          )}
          <FormControl
            label={t('Settings.InternalUsers.ShipperModal.Fields.PhoneNumber.Label.Text')}>
            <TwilioPhoneNumber disabled={loading} record={user} setRecord={setUser} />
          </FormControl>
          <FormControl label={t('Settings.InternalUsers.ShipperModal.Fields.Facility.Label.Text')}>
            <SelectSearch
              multi
              record={user}
              entity="facilities"
              onValuesChange={ids =>
                setUser(user => ({
                  ...user,
                  facilities: ids
                }))
              }
            />
          </FormControl>
          {![YARD_JOCKEY_AUDIENCE, DRIVER].includes(user?.user_type) && (
            <FormControl label={t('Settings.InternalUsers.ShipperModal.AdminCheckBox.Label.Text')}>
              <Checkbox
                disabled={!currentUser?.admin}
                checked={user?.admin}
                onChange={e => {
                  setUser({ ...user, admin: e.currentTarget.checked })
                }}
              />
            </FormControl>
          )}
          {user?.user_type === YARD_JOCKEY_AUDIENCE && (
            <>
              <FormControl label={t('Settings.InternalUsers.Modal.Fields.ExternalId.Label.Text')}>
                <Input
                  autoComplete="off"
                  name="user[external_id]"
                  value={user?.external_id}
                  maxLength={EXTERNAL_ID_MAX_LENGTH}
                  onChange={evt => {
                    setUser({
                      ...user,
                      external_id: evt.currentTarget.value
                    })
                  }}
                />
              </FormControl>
              <FormControl
                label={t('Settings.InternalUsers.Modal.Fields.StartShiftDate.Label.Text')}>
                <DatePicker
                  value={user?.start_shift_date ? new Date(user.start_shift_date) : null}
                  onChange={({ date }) =>
                    setUser({
                      ...user,
                      start_shift_date: date
                    })
                  }
                  placeholder={t('Settings.InternalUsers.Modal.Fields.StartShiftDate.Label.Text')}
                  formatString="MM/dd/yyyy"
                />
              </FormControl>
              <FormControl label={t('Settings.InternalUsers.Modal.Fields.EndShiftDate.Label.Text')}>
                <DatePicker
                  value={user.end_shift_date ? new Date(user.end_shift_date) : null}
                  onChange={({ date }) =>
                    setUser({
                      ...user,
                      end_shift_date: date
                    })
                  }
                  placeholder={t('Settings.InternalUsers.Modal.Fields.EndShiftDate.Label.Text')}
                  formatString="MM/dd/yyyy"
                />
              </FormControl>
              <FormControl
                label={t('Settings.InternalUsers.Modal.Fields.StartShiftTime.Label.Text')}>
                <TimePicker
                  placeholder={t('Settings.InternalUsers.Modal.Fields.StartShiftTime.Label.Text')}
                  step={1800}
                  nullable
                  value={user?.start_shift_date ? new Date(user.start_shift_date) : null}
                  onChange={params => {
                    setUser({
                      ...user,
                      start_shift_date: params
                    })
                  }}
                />
              </FormControl>
              <FormControl label={t('Settings.InternalUsers.Modal.Fields.EndShiftTime.Label.Text')}>
                <TimePicker
                  placeholder={t('Settingsuser.InternalUsers.Modal.Fields.EndShiftTime.Label.Text')}
                  step={1800}
                  nullableuser
                  value={user?.end_shift_date ? new Date(user.end_shift_date) : null}
                  onChange={params =>
                    setUser({
                      ...user,
                      end_shift_date: params
                    })
                  }
                />
              </FormControl>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <FlexGrid flexGridColumnCount={1}>
            <FlexGridItem display="flex" justifyContent="space-between">
              <ErrorButton
                style={{ marginRight: 'auto' }}
                kind="secondary"
                onClick={() => {
                  setOpenConfirmModal(true)
                }}>
                {t('Settings.InternalUsers.ShipperModal.Buttons.RemoveUser.Text')}
              </ErrorButton>
              {user?.disabled ? (
                <DeleteButton
                  label={t('Settings.InternalUsers.ShipperModal.Buttons.ActivateUser.Text')}
                  onDelete={onActivate}
                  buttonProps={{ disabled: loading }}
                />
              ) : (
                <DeleteButton
                  label={t('Settings.InternalUsers.ShipperModal.Buttons.InactivateUser.Text')}
                  onDelete={onInactivate}
                  buttonProps={{ disabled: loading }}
                />
              )}{' '}
              <Button disabled={validateUser(user)} isLoading={loading} onClick={saveModal}>
                {t('Settings.InternalUsers.ShipperModal.Buttons.Save.Text')}
              </Button>
            </FlexGridItem>
          </FlexGrid>
        </ModalFooter>
      </Modal>
      <ConfirmationModal
        isOpen={openConfirmModal}
        loading={loading}
        title={t('Settings.InternalUsers.ShipperModal.ConfirmationModal.Title.Text')}
        cancelAction={() => setOpenConfirmModal(false)}
        confirmAction={onRemove}
      />
    </>
  )
}

export default connectSearchBox(ShipperModal)
