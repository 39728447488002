import { PAGE_SIZE } from 'components/constants/page-size'
import { Order } from 'components/models/Order'
import { PaginationResponse } from 'components/types/pagination-response'
import http, { downloadFile, uploadFile } from 'components/utils/authenticated-fetch'
import {
  ValidateOrderOpenSchedulingRequestBody,
  ValidateOrderRequestBody,
  ValidateOrderResponse
} from './order.type'

const DEFAULT_PARAMS = {
  search: '',
  page: 1,
  pageSize: PAGE_SIZE,
  queryParamsFilters: ''
}

class OrderService {
  findOrders = (params = DEFAULT_PARAMS): Promise<PaginationResponse<Order>> => {
    const query = new URLSearchParams()
    const { queryParamsFilters } = params
    if (params?.page) query.set('page', String(params.page))
    if (params?.pageSize) query.set('page_size', String(params.pageSize))
    if (params?.search) query.set('search', params.search)

    return http({
      path: `/orders.json?${query.toString()}${queryParamsFilters ? queryParamsFilters : ''}`,
      method: 'GET'
    }).then(([data]) => data)
  }

  createOrder = (body: Order): Promise<[string, number]> =>
    http({
      path: '/orders.json',
      method: 'POST',
      body
    }).then(data => data)

  findOrder = (id: string): Promise<[Order, number]> =>
    http({
      path: `/orders/${id}.json`,
      method: 'GET'
    }).then(data => {
      return data
    })

  validateOrder = (body: ValidateOrderRequestBody): Promise<[ValidateOrderResponse, number]> =>
    http({
      path: `/orders/validate.json`,
      method: 'PUT',
      body
    }).then(data => data)

  validateOrderOpenScheduling = (
    body: ValidateOrderOpenSchedulingRequestBody
  ): Promise<[ValidateOrderResponse, number]> =>
    http({
      path: `/orders/validate/open_scheduling.json`,
      method: 'PUT',
      body
    }).then(data => data)

  updateOrder = (body: Order): Promise<[string | { order: string }, number]> =>
    http({
      path: `/orders/${body.id}.json`,
      method: 'PUT',
      body
    }).then(data => data)

  deleteOrder = (id: string): Promise<[string, number]> =>
    http({
      path: `/orders/${id}.json`,
      method: 'DELETE'
    }).then(data => data)

  bulkUploadOrder = (data: FormData): Promise<any> =>
    uploadFile({
      path: '/orders/bulk_upload.json',
      method: 'POST',
      body: data
    })

  generate_template = (): Promise<any> =>
    downloadFile({
      path: `/orders/generate_template.json`,
      method: 'GET'
    }).then(data => {
      return data
    })

  findOrdersCarrier = ({
    orderNumber,
    subdomain
  }: {
    orderNumber: string
    subdomain: string
  }): Promise<Order[]> => {
    const query = new URLSearchParams()
    if (orderNumber) query.set('order_number', String(orderNumber))
    if (subdomain) query.set('subdomain', String(subdomain))

    return http({
      path: `/orders/orders_list_carrier.json?${query.toString()}`,
      method: 'GET'
    }).then(([data]) => data)
  }
}

export const orderService = new OrderService()
