import React, { useEffect, useState } from 'react'
import Checkbox from 'components/ui/generic/Checkbox'
import { useTranslation } from 'react-i18next'
import { useStyletron } from 'baseui'
import { LabelSmall } from 'baseui/typography'
import { StatefulPopover } from 'baseui/popover'
import { Block } from 'baseui/block'
import Button from 'components/ui/generic/Button'
import { KIND } from 'baseui/button'
import { SlidersHorizontal } from '@phosphor-icons/react'

const SelectFilter = ({
  items,
  title,
  onChange
}: {
  items: { label: string; value: boolean; key: string }[]
  title: string
  onChange: (value: any) => any
}) => {
  const [options, setOptions] = useState(items)
  const [css, theme] = useStyletron()
  const { t } = useTranslation()

  useEffect(() => {
    setOptions(items)
  }, [items])

  const setValue = (value, index) => {
    const newOptions = [...items]
    newOptions[index].value = value
    onChange(newOptions)
  }

  const filterItem = ({ label, value, index, setValue }) => {
    return (
      <div
        key={index}
        className={css({
          display: 'flex',
          alignItem: 'center',
          justifyContent: 'space-between',
          marginBottom: theme.sizing.scale0
        })}>
        <Checkbox
          overrides={{
            Label: {
              style: ({ $theme }) => ({
                fontSize: $theme.typography.LabelXSmall.fontSize,
                textTransform: 'capitalize'
              })
            }
          }}
          label={label}
          checked={value}
          onChange={e => {
            setValue(e.currentTarget.checked, index)
          }}
        />
      </div>
    )
  }

  return (
    <StatefulPopover
      content={() => (
        <Block padding={'scale600'} backgroundColor={theme.colors.white}>
          {options.length > 0 ? (
            options
              .sort((first, second) => first.label.localeCompare(second.label))
              .map((item, index) => {
                return filterItem({ ...item, index, setValue })
              })
          ) : (
            <LabelSmall color={theme.colors.contentSecondary}>
              {t('Common.Dropdown.NoOptions.Text')}
            </LabelSmall>
          )}
        </Block>
      )}
      returnFocus
      autoFocus
      overrides={{
        Body: {
          style: ({ $theme }) => ({
            overflow: 'hidden',
            borderTopLeftRadius: $theme.borders.radius200,
            borderTopRightRadius: $theme.borders.radius200,
            borderBottomRightRadius: $theme.borders.radius200,
            borderBottomLeftRadius: $theme.borders.radius200
          })
        },
        Inner: {
          style: ({ $theme }) => ({
            borderTopLeftRadius: $theme.borders.radius200,
            borderTopRightRadius: $theme.borders.radius200,
            borderBottomRightRadius: $theme.borders.radius200,
            borderBottomLeftRadius: $theme.borders.radius200,
            color: $theme.colors.white
          })
        }
      }}>
      <Button kind={KIND.secondary} IconStart={<SlidersHorizontal />}>
        {title}
      </Button>
    </StatefulPopover>
  )
}

export default SelectFilter
