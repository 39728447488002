import http from 'components/utils/authenticated-fetch'
import { CurrentUser } from '../users'

class UserService {
  getUsersByRoleAudience = async (audience: string[], name?: string): Promise<[any, number]> => {
    const params = new URLSearchParams()

    if (name && name !== '') {
      params.append('name', name)
    }

    audience.forEach(audience => {
      params.append(`audience[]`, audience)
    })

    const users = await http({
      path: `/users/search_users_by_role.json?${params.toString()}`
    })

    return users
  }

  create = async (user: CurrentUser): Promise<any> => {
    const users = await http({
      path: `/users.json`,
      method: 'POST',
      body: {
        user
      }
    })

    return users
  }

  update = async (user: CurrentUser): Promise<any> => {
    const users = await http({
      path: `/users/${user.id}.json`,
      method: 'PATCH',
      body: {
        user
      }
    })

    return users
  }

  endShift = async (userId: string): Promise<any> => {
    const users = await http({
      path: `/users/${userId}/end_self_shift.json`,
      method: 'PUT'
    })

    return users
  }
}

export const userService = new UserService()
