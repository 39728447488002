import React, { createContext, useContext, useReducer, ReactNode, useEffect } from 'react'
import appointmentReducer, {
  SET_APPOINTMENT_DIRECTION,
  SET_HANDLING_METHOD,
  SET_LOADING,
  SET_SCHEDULER_EMAIL,
  SET_APPOINTMENT,
  RESET_APPOINTMENT_DIRECTION,
  SET_IS_TIME_SET,
  SET_APPOINTMENT_STATE,
  SET_SHOW_RECURRING,
  SET_CHECKED_RECURRING,
  SET_OPEN_RECURRING,
  SET_RECURRING,
  OPEN_TERMS_OF_SERVICE_MODAL,
  IS_TERMS_OF_SERVICE_CHECKED,
  SET_EDIT_APPOINTMENT_ID,
  SET_LOADING_DATA_APPOINTMENT,
  SET_FACILITY_EDIT_APPOINTMENT,
  SET_RESCHEDULE_REASON,
  SET_UNIQUE_LOAD_IDENTIFIER_ERROR,
  SET_RECAPTCHA,
  SET_FROM_ORDER,
  SET_APPOINTMENT_CHECKED_OUT,
  SET_DRIVER
} from 'components/reducers/appointment.reducer'
import { CurrentUserContext } from 'components/homepage/current-user-context'
import { appointmentService, milestoneService } from 'components/services'
import { fancyToast } from 'components/utils'
import { useCarriersContext } from './carriers.context'
import { useSafeDispatch } from 'components/hooks/use-safe-dispatch'
import { Answer, Appointment } from 'components/appointments/types'

import { AppointmentDirectionType } from 'components/appointments/types'
import { CARRIER, SHIPPER } from 'components/models/User'
import { DROP, LIVE } from 'components/constants/handling_method'
import { lowerCase } from 'lodash'
import { useTranslation } from 'react-i18next'
import { CHECKED_OUT } from 'components/utils/appointment-statuses'
import { orderService } from 'components/services/order'
import { DEFAULT_SHIPPER_SUBDOMAIN } from 'components/constants/default-shipper-subdomain'
import { Order, OUTBOUND_ORDER_TYPE } from 'components/models/Order'
import { Milestones } from 'components/models/Milestones'
import { StatusCodes } from 'components/constants/http-status-codes'

// Types for appointment and state
export interface AppointmentType {
  id?: string
  facilityId: string
  arrivalTime: Date
  purchaseOrdersAttributes: any[]
  initialPOIds?: string[]
  appointmentTypeId: string
  equipmentTypeId: string
  schedulerId: string
  handlingMethod: string
  carrierId: string
  answersAttributes: Answer[]
  trailer: {
    number: string
    state: string
    status: string
  }
}

interface AppointmentsState {
  appointmentId: string
  loading: boolean
  handlingMethod: string
  confirmationIds: string[]
  appointmentDirections: AppointmentDirectionType[]
  appointments: { inbound: AppointmentType; outbound: AppointmentType }
  schedulerEmail: {
    email: string
    displayError: boolean
    disabled: boolean
  }
  driverId: string
  fromOrder: boolean
  recurring: {
    showRecurring: boolean
    checked: boolean
    showRecurringFields: boolean
    endDate: Date
    recurringDays: Array<number>
  }
  termsOfService: {
    isTermsOfServiceChecked: boolean
    showTermsOfServiceModal: boolean
  }
  appointmentEdit?: {
    facilityId: string
    loadingData: boolean
    rescheduleReason: string[]
    appointmentCheckedOut?: boolean
    recaptcha: boolean
  }
  createAppointmentButtonDisableFlags: {
    isCreateAppointmentButtonDisabled: boolean
    isTimeSet: boolean
    isInboundUniqueLoadIdentifierError: boolean
    isOutboundUniqueLoadIdentifierError: boolean
    errors: any[]
  }
}

interface AppointmentContextType {
  state: AppointmentsState
  actions: {
    setAppointment: (payload: any, direction?: string) => void
    setHandlingMethod: (method: string) => void
    setAppointmentDirection: (direction: AppointmentDirectionType[], value: string) => void
    setSchedulerEmail: (email: { email: string; displayError: boolean; disabled: boolean }) => void
    setDriver: (driverId: string) => void
    setLoading: (loading: boolean) => void
    createAppointment: () => Promise<boolean>
    createAppointmentOpenScheduling: () => Promise<boolean>
    updateAppointment: () => Promise<boolean>
    updateAppointmentOpenScheduling: () => Promise<boolean>
    setIsTimeSet: (isTimeSet: boolean) => void
    resetAppointmentStates: () => void
    setAppointmentState: (payload: any) => void
    setEnabledRecurring: (enable: any) => void
    setCheckedRecurring: (checked: any) => void
    setOpenRecurring: (open: any) => void
    setRecurring: (recurring: any) => void
    setShowTermsOfServiceModal: (open: boolean) => void
    shouldDisableCancelButton: () => void
    setIsTermsOfServiceChecked: (checked: boolean) => void
    setEditAppointment: (appointmentId: string, loadData?: boolean) => void
    loadAppointmentData: (appointmentId: any) => void
    getAppointmentIds: () => string[]
    setRescheduleReason: (reason: string[]) => void
    setUniqueLoadIdentifierError: (error: any, direction: string) => void
    setRecaptcha: (completed: boolean) => void
    changeApprovalWorflow: (approved: boolean) => Promise<boolean>
    getSchedulerIds: () => string[]
  }
  key: number
}

export const IS_DROP = 'drop'
export const IS_LIVE = 'live'
export const INBOUND = 'inbound'
export const OUTBOUND = 'outbound'

export const initialAppointment: AppointmentType = {
  facilityId: '',
  arrivalTime: null,
  purchaseOrdersAttributes: [],
  appointmentTypeId: '',
  equipmentTypeId: '',
  schedulerId: '',
  handlingMethod: IS_LIVE,
  carrierId: '',
  answersAttributes: [],
  trailer: {
    number: '',
    state: '',
    status: ''
  }
}

const initialState: AppointmentsState = {
  appointmentId: null,
  loading: false,
  handlingMethod: IS_LIVE,
  confirmationIds: [],
  appointmentDirections: [INBOUND],
  appointments: {
    inbound: { ...initialAppointment },
    outbound: { ...initialAppointment }
  },
  schedulerEmail: {
    email: '',
    displayError: false,
    disabled: false
  },
  driverId: null,
  fromOrder: false,
  recurring: {
    showRecurring: false,
    checked: false,
    showRecurringFields: false,
    endDate: null,
    recurringDays: []
  },
  termsOfService: {
    isTermsOfServiceChecked: false,
    showTermsOfServiceModal: false
  },
  appointmentEdit: {
    facilityId: null,
    loadingData: false,
    rescheduleReason: [],
    recaptcha: !!(process.env.RAILS_ENV === 'test' || process.env.RAILS_ENV === 'development')
  },
  createAppointmentButtonDisableFlags: {
    isCreateAppointmentButtonDisabled: true,
    isTimeSet: false,
    isInboundUniqueLoadIdentifierError: false,
    isOutboundUniqueLoadIdentifierError: false,
    errors: []
  }
}

export const AppointmentContext = createContext<AppointmentContextType | undefined>(undefined)

export const AppointmentProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(appointmentReducer, initialState)
  const queryParams = new URLSearchParams(location?.search)
  const safeDispatch = useSafeDispatch(dispatch)
  const {
    actions: { resetCarrierState }
  } = useCarriersContext()
  const { currentUser } = useContext(CurrentUserContext)
  const subdomain = window.location.hostname.split('.')[0]
  const envSubdomain = subdomain === 'localhost' ? DEFAULT_SHIPPER_SUBDOMAIN : subdomain
  const { t } = useTranslation()

  const [key, setKey] = React.useState(0)

  useEffect(() => {
    const abortController = new AbortController()
    if (currentUser && currentUser.emailAddress) {
      if (currentUser.userType === SHIPPER) {
        dispatch({
          type: SET_SHOW_RECURRING,
          payload: true
        })
      }
      dispatch({
        type: SET_SCHEDULER_EMAIL,
        payload: {
          field: 'schedulerEmail',
          value: {
            email: currentUser.emailAddress,
            displayError: false,
            disabled: currentUser.emailAddress.length > 0
          }
        }
      })
    }
    if (currentUser && currentUser?.userType === CARRIER) {
      const orderNumber = queryParams.get('orderNumber')
      orderNumber && loadOrderData(orderNumber)
    }
    ;() => {
      abortController.abort()
    }
  }, [currentUser])

  const setAppointmentState = (payload: any) => {
    dispatch({ type: SET_APPOINTMENT_STATE, payload })
  }

  const setAppointment = (payload: any, direction?: string) => {
    safeDispatch({ type: SET_APPOINTMENT, payload, direction, userType: currentUser?.userType })
  }

  const setHandlingMethod = (method: string) => {
    dispatch({ type: SET_HANDLING_METHOD, payload: method })
  }

  const setAppointmentDirection = (direction: string[], value: string) => {
    if (state.appointmentDirections.includes(value)) resetAppointmentDirectionState(value)
    dispatch({
      type: SET_APPOINTMENT_DIRECTION,
      payload: direction,
      userType: currentUser?.userType
    })
    setKey(prevKey => prevKey + 1)
  }

  const setLoading = (loading: boolean) => {
    dispatch({ type: SET_LOADING, payload: loading })
  }

  const setSchedulerEmail = (email: {
    email: string
    displayError: boolean
    disabled: boolean
  }) => {
    dispatch({
      type: SET_SCHEDULER_EMAIL,
      payload: {
        field: 'schedulerEmail',
        value: email
      }
    })
  }

  const resetAppointmentStates = () => {
    dispatch({
      type: SET_APPOINTMENT_STATE,
      payload: {
        ...initialState,
        confirmationId: state.confirmationId,
        schedulerEmail: state.schedulerEmail
      }
    })
    setKey(prevKey => prevKey + 1)
  }
  const resetAppointmentDirectionState = direction => {
    const { handlingMethod, facilityId, arrivalTime, ...rest } = initialAppointment
    dispatch({ type: RESET_APPOINTMENT_DIRECTION, payload: { ...rest }, direction: direction })
  }

  const setIsTimeSet = (isTimeSet: boolean) => {
    dispatch({ type: SET_IS_TIME_SET, payload: isTimeSet })
  }

  const createAppointment = async () => {
    setLoading(true)
    // Prepare appointment data by removing the questions attribute
    const prepareData = appointment => {
      const { questions, ...appointmentData } = appointment // Destructure to exclude questions
      appointmentData['driverId'] = state?.driverId
      return appointmentData
    }

    let appointmentData = {}

    if (state.handlingMethod === IS_DROP) {
      appointmentData['appointments'] = state.appointmentDirections.map(direction =>
        prepareData(state.appointments[direction])
      )
    } else {
      appointmentData['appointment'] = prepareData(state.appointments.inbound)
    }

    const [json, status] = await appointmentService.create(appointmentData)

    if (status === 201) {
      resetCarrierState()

      const tempConfirmations = []
      const tempFacilityId = state.appointments.inbound.facilityId

      Array.isArray(json)
        ? json.forEach(appointment => {
            tempConfirmations.push({
              confirmationId: appointment.confirmationId,
              appointmentTypeId: appointment.appointmentTypeId,
              appointmentId: appointment.id
            })
          })
        : tempConfirmations.push({
            confirmationId: json.confirmationId,
            appointmentTypeId: json.appointmentTypeId,
            appointmentId: json.id
          })

      // Ensure schedulerEmail is not reset
      const { schedulerEmail, ...rest } = initialState
      setAppointmentState({ ...rest, confirmationIds: tempConfirmations })
      // Ensure the facilityId is set after state reset
      setAppointment({ facilityId: tempFacilityId })
      setLoading(false)
      return true
    } else {
      fancyToast(json, status)
      setTimeout(() => {
        setLoading(false)
      }, 3000)
    }
    return false
  }

  const createAppointmentOpenScheduling = async () => {
    setLoading(true)
    const prepareData = appointment => {
      const { questions, ...appointmentData } = appointment
      appointmentData['schedulerEmail'] = state?.schedulerEmail?.email
      return appointmentData
    }

    let appointmentData = {}

    if (state.handlingMethod === IS_DROP) {
      appointmentData['appointments'] = state.appointmentDirections.map(direction =>
        prepareData(state.appointments[direction])
      )
    } else {
      appointmentData['appointment'] = prepareData(state.appointments.inbound)
    }

    const [json, status] = await appointmentService.createOpenScheduling(appointmentData)

    if (status === 201) {
      resetCarrierState()

      const tempConfirmations = []
      const tempFacilityId = state.appointments.inbound.facilityId

      Array.isArray(json)
        ? json.forEach(appointment => {
            tempConfirmations.push({
              confirmationId: appointment.confirmationId,
              appointmentTypeId: appointment.appointmentTypeId,
              appointmentId: appointment.id
            })
          })
        : tempConfirmations.push({
            confirmationId: json.confirmationId,
            appointmentTypeId: json.appointmentTypeId,
            appointmentId: json.id
          })

      const { schedulerEmail, ...rest } = initialState
      setAppointmentState({ ...rest, confirmationIds: tempConfirmations })
      setAppointment({ facilityId: tempFacilityId })
      setLoading(false)
      return true
    } else {
      fancyToast(json, status)
      setTimeout(() => {
        setLoading(false)
      }, 3000)
    }
    return false
  }

  const setEnabledRecurring = (enable: boolean) => {
    dispatch({ type: SET_SHOW_RECURRING, payload: enable })
  }

  const setCheckedRecurring = (checked: boolean) => {
    dispatch({ type: SET_CHECKED_RECURRING, payload: checked })
  }

  const setOpenRecurring = (open: boolean) => {
    dispatch({ type: SET_OPEN_RECURRING, payload: open })
  }

  const setRecurring = (open: boolean) => {
    dispatch({ type: SET_RECURRING, payload: open })
  }

  const setShowTermsOfServiceModal = (open: boolean) => {
    dispatch({ type: OPEN_TERMS_OF_SERVICE_MODAL, payload: open })
  }

  const setIsTermsOfServiceChecked = (checked: boolean) => {
    dispatch({ type: IS_TERMS_OF_SERVICE_CHECKED, payload: checked })
  }

  const loadAppointmentData = appointment => {
    if (appointment) {
      setFacility(appointment?.facilityId)
      setAppointmentState({
        handlingMethod: appointment?.handlingMethod,
        appointmentDirections: loadAppointmentDirections(appointment),
        appointments: formatEditAppointent(appointment),
        driverId: appointment?.driverId
      })
      setIsTimeSet(true)
      setAppointmentStatus(appointment)
    }
  }

  const loadOrderData = async (orderNumber: string) => {
    try {
      setLoading(true)
      const orders = await orderService.findOrdersCarrier({
        orderNumber: orderNumber,
        subdomain: envSubdomain
      })

      if (orders.length) {
        setAppointmentFromOrder(orders[0])
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const setAppointmentFromOrder = (order: Order) => {
    if (order && order.facilitiesId) {
      const direction = getAppointmentDirectionFromOrder(order)
      setFacility(order?.facilitiesId)
      const appointments = {
        ...initialState.appointments,
        [direction]: {
          ...initialState.appointments[direction],
          facilityId: order?.facilitiesId,
          purchaseOrdersAttributes: [{ id: null, identifier: order?.number }],
          schedulerId: order?.schedulerId,
          handlingMethod: DROP
        }
      }
      setAppointmentState({
        handlingMethod: DROP,
        appointmentDirections: [direction],
        appointments: appointments,
        fromOrder: true
      })
    }
  }

  const getAppointmentDirectionFromOrder = (order: Order) => {
    if (OUTBOUND_ORDER_TYPE.includes(order.orderType)) {
      return OUTBOUND
    }
    return INBOUND
  }

  const setFacility = (facilityId: string) => {
    dispatch({ type: SET_FACILITY_EDIT_APPOINTMENT, payload: facilityId })
  }

  const setAppointmentStatus = (appointment: any) => {
    dispatch({
      type: SET_APPOINTMENT_CHECKED_OUT,
      payload:
        appointment?.status == CHECKED_OUT || appointment?.relatedAppointment?.status == CHECKED_OUT
    })
  }

  const seLoadingData = (loading: boolean) => {
    dispatch({ type: SET_LOADING_DATA_APPOINTMENT, payload: loading })
  }

  const loadAppointmentDirections = ({ handlingMethod, appointmentType, relatedAppointment }) => {
    if (handlingMethod === LIVE) return [INBOUND]

    if (handlingMethod === DROP) {
      const directions = [
        lowerCase(appointmentType),
        lowerCase(relatedAppointment?.appointmentType)
      ].filter(type => [INBOUND, OUTBOUND].includes(type))
      return directions
    }
    return []
  }

  const formatEditAppointent = data => {
    const isDropAppoinment = data?.handlingMethod == DROP
    let appointments = initialState.appointments
    if (!isDropAppoinment) {
      appointments = {
        ...appointments,
        inbound: {
          ...appointments.inbound,
          id: data?.id,
          facilityId: data?.facilityId,
          arrivalTime: data?.arrivalTime ? new Date(data?.arrivalTime) : null,
          purchaseOrdersAttributes: data?.purchaseOrders,
          initialPOIds: getInitialPOIds(data?.purchaseOrders),
          appointmentTypeId: data?.appointmentTypeId,
          equipmentTypeId: data?.equipmentTypeId,
          schedulerId: data?.schedulerId,
          handlingMethod: data?.handlingMethod,
          carrierId: data?.carrierId,
          answersAttributes: data?.answersAttributes
        }
      }
    }

    if (isDropAppoinment) {
      const appointmentType = lowerCase(data?.appointmentType)
      if ([INBOUND, OUTBOUND].includes(appointmentType)) {
        appointments = {
          ...appointments,
          [appointmentType]: {
            id: data?.id,
            facilityId: data?.facilityId,
            arrivalTime: data?.arrivalTime ? new Date(data?.arrivalTime) : null,
            purchaseOrdersAttributes: data?.purchaseOrders,
            initialPOIds: getInitialPOIds(data?.purchaseOrders),
            appointmentTypeId: data?.appointmentTypeId,
            equipmentTypeId: data?.equipmentTypeId,
            schedulerId: data?.schedulerId,
            handlingMethod: data?.handlingMethod,
            carrierId: data?.carrierId,
            trailer: {
              number: data?.trailer?.number,
              state: data?.trailer?.state,
              status: data?.trailer?.status
            },
            answersAttributes: data?.answersAttributes
          }
        }
      }
      const relatedAppointmentType = lowerCase(data?.relatedAppointment?.appointmentType)
      if ([INBOUND, OUTBOUND].includes(relatedAppointmentType)) {
        appointments = {
          ...appointments,
          [relatedAppointmentType]: {
            id: data?.relatedAppointment?.id,
            facilityId: data?.relatedAppointment?.facilityId,
            arrivalTime: data?.relatedAppointment?.arrivalTime
              ? new Date(data?.relatedAppointment?.arrivalTime)
              : null,
            purchaseOrdersAttributes: data?.relatedAppointment?.purchaseOrders,
            initialPOIds: getInitialPOIds(data?.relatedAppointment?.purchaseOrders),
            appointmentTypeId: data?.relatedAppointment?.appointmentTypeId,
            equipmentTypeId: data?.relatedAppointment?.equipmentTypeId,
            schedulerId: data?.relatedAppointment?.schedulerId,
            handlingMethod: data?.relatedAppointment?.handlingMethod,
            carrierId: data?.relatedAppointment?.carrierId,
            trailer: {
              number: data?.relatedAppointment?.trailer?.number,
              state: data?.relatedAppointment?.trailer?.state,
              status: data?.relatedAppointment?.trailer?.status
            },
            answersAttributes: data?.relatedAppointment?.answersAttributes
          }
        }
      }
    }

    return appointments
  }

  const getInitialPOIds = (purchaseOrders: { id: string; identifier: string }[]) => {
    return purchaseOrders?.map(po => po.id) || []
  }

  const getAppointmentData = (id: string) => {
    setLoading(true)
    appointmentService
      .get(id)
      .then(([data, status]) => {
        if ([304, 200].includes(status)) {
          loadAppointmentData(data)
        }
        setLoading(false)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const getAppointmentDataOpenScheduling = (id: string) => {
    setLoading(true)
    appointmentService
      .getOpenScheduling(id)
      .then(([data, status]) => {
        if ([304, 200].includes(status)) {
          loadAppointmentData(data)
        }
        setLoading(false)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const setEditAppointment = (id: string, loadData: boolean = false) => {
    dispatch({ type: SET_EDIT_APPOINTMENT_ID, payload: id })
    if (loadData) {
      if (currentUser) {
        getAppointmentData(id)
      } else {
        getAppointmentDataOpenScheduling(id)
      }
    }
  }

  const updateAppointment = async () => {
    setLoading(true)
    const rescheduleReason = getRescheduleReason()
    // Prepare appointment data by removing the questions attribute
    const prepareData = appointment => {
      const { questions, ...appointmentData } = appointment
      appointmentData['driverId'] = state?.driverId
      return {
        ...appointmentData,
        comment: rescheduleReason
      }
    }

    let appointmentData = {}
    let primaryAppointmentId = state.appointments.inbound?.id
      ? state.appointments.inbound.id
      : state.appointments.outbound?.id
    if (state.handlingMethod === IS_DROP) {
      appointmentData['appointments'] = state.appointmentDirections.map(direction =>
        prepareData(state.appointments[direction])
      )
    } else {
      appointmentData['appointment'] = prepareData(state.appointments.inbound)
    }

    const [json, status] = await appointmentService.update(primaryAppointmentId, appointmentData)

    if (status === 200) {
      const tempConfirmations = []
      const tempFacilityId = state.appointments.inbound.facilityId

      Array.isArray(json)
        ? json.forEach(appointment => {
            tempConfirmations.push({
              confirmationId: appointment.confirmationId,
              appointmentTypeId: appointment.appointmentTypeId,
              appointmentId: appointment.id
            })
          })
        : tempConfirmations.push({
            confirmationId: json.confirmationId,
            appointmentTypeId: json.appointmentTypeId,
            appointmentId: json.id
          })
      setLoading(false)
      const { schedulerEmail, ...rest } = initialState
      setAppointmentState({ ...rest, confirmationIds: tempConfirmations })
      setAppointment({ facilityId: tempFacilityId })
      return true
    } else {
      fancyToast(json, status)
    }
    return false
  }

  const updateAppointmentOpenScheduling = async () => {
    const rescheduleReason = getRescheduleReason()
    const prepareData = appointment => {
      const { questions, ...appointmentData } = appointment
      appointmentData['schedulerEmail'] = state?.schedulerEmail?.email
      return {
        ...appointmentData,
        comment: rescheduleReason
      }
    }

    let appointmentData = {}
    let primaryAppointmentId = state.appointments.inbound?.id
      ? state.appointments.inbound.id
      : state.appointments.outbound?.id
    if (state.handlingMethod === IS_DROP) {
      appointmentData['appointments'] = state.appointmentDirections.map(direction =>
        prepareData(state.appointments[direction])
      )
    } else {
      appointmentData['appointment'] = prepareData(state.appointments.inbound)
    }

    const [json, status] = await appointmentService.updateOpenScheduling(
      primaryAppointmentId,
      appointmentData
    )

    if (status === 200) {
      const tempConfirmations = []
      const tempFacilityId = state.appointments.inbound.facilityId

      Array.isArray(json)
        ? json.forEach(appointment => {
            tempConfirmations.push({
              confirmationId: appointment.confirmationId,
              appointmentTypeId: appointment.appointmentTypeId,
              appointmentId: appointment.id
            })
          })
        : tempConfirmations.push({
            confirmationId: json.confirmationId,
            appointmentTypeId: json.appointmentTypeId,
            appointmentId: json.id
          })
      setLoading(false)
      const { schedulerEmail, ...rest } = initialState
      setAppointmentState({ ...rest, confirmationIds: tempConfirmations })
      setAppointment({ facilityId: tempFacilityId })
      return true
    } else {
      fancyToast(json, status)
    }
    return false
  }

  const getRescheduleReason = (): string => {
    if (state.appointmentEdit?.rescheduleReason && state.appointmentEdit?.rescheduleReason.length)
      return state.appointmentEdit?.rescheduleReason[0]?.id
    return null
  }

  const getAppointmentIds = () => {
    return state.appointmentDirections.map(direction => state.appointments[direction]?.id)
  }

  const setRescheduleReason = (reason: any[]) => {
    dispatch({ type: SET_RESCHEDULE_REASON, payload: reason })
  }

  const setUniqueLoadIdentifierError = (error: any, direction: string) => {
    dispatch({ type: SET_UNIQUE_LOAD_IDENTIFIER_ERROR, payload: error, direction })
  }

  const setRecaptcha = (completed: boolean) => {
    dispatch({ type: SET_RECAPTCHA, payload: completed })
  }

  const setFromOrder = (isOrder: boolean) => {
    dispatch({ type: SET_FROM_ORDER, payload: isOrder })
  }
  
  const changeApprovalWorflow = async (approved: boolean): Promise<boolean> => {
    try {
      const params: Milestones = {
        ...(approved ? { approvedAt: new Date() } : { declinedAt: new Date() }),
        appointmentId: state.appointments.inbound?.id ?? state.appointments.outbound?.id,
        facilityId:
          state.appointments.inbound?.facilityId ?? state.appointments.outbound?.facilityId
      }
      const [result, status] = await milestoneService.update(params)
      if (status === StatusCodes.OK || status === StatusCodes.CREATED) {
        fancyToast(
          {
            info: t('Common.Info.Interpolated.Text', {
              model: t('Common.ModelType.Appointment.Text'),
              action: t('Common.Actions.Updated.Text')
            })
          },
          StatusCodes.OK
        )
        return true
      } else {
        fancyToast(
          {
            info: t('Common.Errors.Interpolated.Text', {
              model: t('Common.ModelType.Appointment.Text'),
              action: t('Common.Actions.Updated.Text')
            })
          },
          status
        )
      }
    } catch (error) {
      fancyToast(
        {
          info: t('Common.Errors.Interpolated.Text', {
            model: t('Common.ModelType.Appointment.Text'),
            action: t('Common.Actions.Updated.Text')
          })
        },
        StatusCodes.INTERNAL_SERVER_ERROR
      )
    }
    return false
  }
  
  
  const getSchedulerIds = () => {
    const schedulerIds = []
    if (currentUser?.userType == CARRIER) {
      state.appointmentDirections.forEach(direction => {
        schedulerIds.push(state.appointments[direction]?.schedulerId)
      })
    } else {
      currentUser?.schedulerId && schedulerIds.push(currentUser?.schedulerId)
    }
    return schedulerIds
  }

  const shouldDisableCancelButton = () => {
    let disable = true

    state.appointmentDirections.forEach((direction: string) => {
      const appointment = state.appointments[direction]

      if (
        appointment?.purchaseOrdersAttributes?.length > 0 ||
        appointment?.appointmentTypeId !== ''
      ) {
        disable = false
      }
    })

    return disable
  }

  const setDriver = (driverId: string) => {
    dispatch({
      type: SET_DRIVER,
      payload: {
        value: driverId
      }
    })
  }

  const actions = {
    setAppointment,
    setHandlingMethod,
    setAppointmentDirection,
    setSchedulerEmail,
    setLoading,
    createAppointment,
    createAppointmentOpenScheduling,
    setIsTimeSet,
    resetAppointmentStates,
    setAppointmentState,
    setEnabledRecurring,
    setCheckedRecurring,
    setOpenRecurring,
    setRecurring,
    setShowTermsOfServiceModal,
    setIsTermsOfServiceChecked,
    setEditAppointment,
    updateAppointment,
    updateAppointmentOpenScheduling,
    seLoadingData,
    loadAppointmentData,
    getAppointmentIds,
    setRescheduleReason,
    setUniqueLoadIdentifierError,
    setRecaptcha,
    shouldDisableCancelButton,
    setDriver,
    changeApprovalWorflow,
    getSchedulerIds
  }

  return (
    <AppointmentContext.Provider value={{ state, actions, key }}>
      {children}
    </AppointmentContext.Provider>
  )
}

export const useAppointmentContext = (): AppointmentContextType => {
  const context = useContext(AppointmentContext)
  if (context === undefined) {
    throw new Error('useAppointmentContext must be used within an AppointmentProvider')
  }
  return context
}
