import { Modal, ModalBody, ModalFooter, ModalHeader } from 'baseui/modal'
import React, { useContext, useEffect, useState } from 'react'

import ErrorMessageButton from 'components/shared/error-message-button'
import { Block } from 'baseui/block'
import { useTranslation } from 'react-i18next'

interface ConfirmationModalProps {
  isOpen: boolean
  loading: boolean
  title: string
  errors?: any[]
  cancelAction: () => void
  confirmAction: () => void
}
const ConfirmUpdateModal = ({
  isOpen,
  loading: saveStarts,
  title,
  errors,
  cancelAction,
  confirmAction
}: ConfirmationModalProps) => {
  const { t } = useTranslation()

  return (
    <Modal unstable_ModalBackdropScroll onClose={cancelAction} isOpen={isOpen} closeable={false}>
      <ModalHeader>{title}</ModalHeader>
      <ModalBody></ModalBody>
      <ModalFooter>
        <Block display="flex" alignItems="center" justifyContent="flex-end" gridGap="scale600">
          <ErrorMessageButton
            label={t('Appointments.EditDropAppointmentModal.Actions.ConfirmUpdateModal.Cancel')}
            errors={[]}
            buttonProps={{
              onClick: cancelAction,
              kind: 'secondary'
            }}
          />
          <ErrorMessageButton
            label={t('Appointments.EditDropAppointmentModal.Actions.ConfirmUpdateModal.Confirm')}
            errors={errors || []}
            buttonProps={{
              isLoading: saveStarts,
              onClick: confirmAction
            }}
          />
        </Block>
      </ModalFooter>
    </Modal>
  )
}

export default ConfirmUpdateModal
