import { DRIVER, SHIPPER, YARD_JOCKEY_AUDIENCE } from '../models/Role'

export const getUserTypeForInternals = (audience: string) => {
  if (audience === YARD_JOCKEY_AUDIENCE) {
    return YARD_JOCKEY_AUDIENCE
  }

  if (audience === DRIVER) {
    return DRIVER
  }

  return SHIPPER
}
