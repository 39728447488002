import React, { useState, useEffect, useContext } from 'react'
import Select from 'components/ui/generic/Select'
import { useTranslation } from 'react-i18next'
import { userService } from 'components/services/user.service'
import { StatusCodes } from 'components/constants/http-status-codes'
import { DRIVER } from 'components/models/Role'
import { useDebounce } from 'react-use'
import { Block } from 'baseui/block'

const SelectDriverUserField = ({ selectedDriver, setSelectedDriver }) => {
  const [options, setOptions] = useState([])
  const [search, setSearch] = useState(null)
  const { t } = useTranslation()

  const searchUsers = (name: string) =>
    userService
      .getUsersByRoleAudience([DRIVER], name)
      .then(([data, result]) => {
        if (result === StatusCodes.OK) {
          setOptions(data)
        }
      })
      .catch(e => console.error(e))

  useEffect(() => {
    searchUsers(null)
  }, [])

  useDebounce(
    () => {
      setSearch(search)
      searchUsers(search)
    },
    200,
    [search]
  )

  return (
    <Block marginTop="20px">
      <Select
        searchable={true}
        valueKey="value"
        label={t('Scheduler.OpenScheduling.Steps.Fields.Driver.Label.Text')}
        options={options}
        value={options.find(f => f.value === selectedDriver)}
        placeholder=""
        onChange={params => {
          setSelectedDriver(params.value[0].value)
        }}
        onInputChange={evt => {
          setSearch(evt.currentTarget.value)
        }}
      />
    </Block>
  )
}

export default SelectDriverUserField
