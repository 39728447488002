import styled from 'styled-components'

export const BulkAppointmentMappingContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 640px;
`

export const BulkAppointmentMappingHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`

export const BulkAppointmentMappingHeaderSelect = styled.div`
  width: 50%;
`

export const BulkAppointmentMappingAddColumn = styled.div`
  align-self: end;
  margin-top: 15px;
  margin-bottom: 27px;
`

export const BulkAppointmentMappingColumnContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;
`

export const BulkAppointmentMappingFixedFields = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 75px;
  flex: 1;
`

export const BulkAppointmentMappingColumn = styled.div<{ isDragging; draggableStyle }>`
  border: 1px solid #dbe6ff;
  background-color: #fff;
  border-radius: 8px;
  width: 100%;
  height: 40px;
  margin-bottom: 8px;
  margin-right: 32px;
  display: flex;
  align-items: center;
  justify-content: start;
  user-select: none;
  opacity: ${({ isDragging }) => (isDragging ? 0.6 : 1)};
  position: relative;
`

export const BulkAppointmentMappingDropList = styled.div`
  width: auto;
`

export const BulkAppointmentMappingHamburguerIcon = styled.div`
  opacity: 0.6;
  margin-left: 12px;
  transform: translateY(3px);
`

export const BulkAppointmentMappingCancelIcon = styled.div`
  opacity: 0.6;
  position: absolute;
  right: 12px;
  top: 10px;
  cursor: pointer;
`
