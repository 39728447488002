import React, { useRef, useState, useCallback, useEffect } from 'react'
import VInput from 'components/ui/generic/Input'
import { Tag, VARIANT as TAG_VARIANT, KIND } from 'baseui/tag'
import { useStyletron } from 'baseui'
import { StyledInput } from 'baseui/input'
import { MonoLabelXSmall } from 'baseui/typography'
import { t } from 'i18next'
import { appointmentService } from 'components/services'
import { Block } from 'baseui/block'
import { CARRIER, SHIPPER } from 'components/models/User'
import { useAppointmentContext } from 'components/contexts/appointment-context'
import { SET_UNIQUE_LOAD_IDENTIFIER_ERROR } from 'components/reducers/appointment.reducer'

import FormControl from 'components/ui/generic/FormControl'
import { DEFAULT_SHIPPER_SUBDOMAIN } from 'components/constants/default-shipper-subdomain'
import { ValidateResponse } from 'components/types/orderValidationResponse'
import OrdersDetails from 'components/facilities/OrdersDetails'

const defaultValidationOrder: ValidateResponse = {
  canProceed: true,
  errors: [],
  orders: [],
  totals: {}
}

const InputReplacement = React.forwardRef(({ tags, removeTag, value, ...restProps }: any, ref) => {
  const [css] = useStyletron()
  return (
    <div
      className={css({
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        flex: '1 1 0%'
      })}>
      {tags.map((tag, index) => (
        <Tag
          key={index}
          variant={TAG_VARIANT.solid}
          kind={KIND.neutral}
          onActionClick={() => removeTag(tag)}
          overrides={{
            Root: {
              style: ({ $theme }) => ({
                backgroundColor: $theme.colors.backgroundTertiary,
                color: $theme.colors.contentSecondary
              })
            },
            Text: {
              style: ({ $theme }) => ({
                fontSize: $theme.typography.LabelXSmall.fontSize,
                maxWidth: '13em'
              })
            }
          }}>
          {tag.identifier}
        </Tag>
      ))}
      <StyledInput ref={ref} value={value} {...restProps} />
    </div>
  )
})

const UniqueIdentifiers = ({
  onChange,
  appointment,
  label,
  currentUser,
  direction,
  facility = null
}) => {
  const {
    actions: { setUniqueLoadIdentifierError }
  } = useAppointmentContext()
  const { facilityId } = appointment
  const inputRef = useRef(null)
  const [value, setValue] = useState('')
  const [tags, setTags] = useState([])
  const [orderValidation, setOrderValidation] = useState<ValidateResponse>(defaultValidationOrder)
  const subdomain = window.location.hostname.split('.')[0]
  const inputLabel =
    label || t('Scheduler.OpenScheduling.Steps.Fields.PurchaseOrderIdentifiers.Label.Text')
  const validateUserType = useCallback(
    newTags => {
      if (currentUser && currentUser?.userType !== SHIPPER) {
        fetchULIPossibleErrors(newTags)
      } else if (!currentUser) {
        fetchULIPossibleErrorsOpenScheduling(newTags)
      } else {
        setOrderValidation(defaultValidationOrder)
      }
    },
    [currentUser?.userType, appointment?.schedulerId]
  )

  const addTag = tag => {
    let newTags = [...tags, tag]
    updateTags(newTags)
  }
  const removeTag = tag => {
    let newTags = tags.filter(t => t !== tag)
    updateTags(newTags)
  }

  const updateTags = newTags => {
    setTags(newTags)
    onChange(newTags)
    validateUserType(newTags)
  }

  const handleKeyDown = event => {
    // listen for enter key
    if (event.keyCode === 13 || event.keyCode === 9) {
      if (!value) return // if no value, do nothing
      if (tags.find(t => t.identifier === value)) return // Prevent duplicate tags
      addTag({ identifier: value, id: null })
      setValue('') // Clear the input
      if (inputRef.current) inputRef.current.value = '' // Clear the input value
    } else if (event.keyCode === 8 && !value && tags.length) {
      // Backspace
      removeTag(tags[tags.length - 1])
      if (inputRef.current) inputRef.current.focus() // Focus the input again after removing
    }
  }

  const handleBlour = event => {
    if (!value) return
    if (tags.find(t => t.identifier === value)) return
    addTag({ identifier: value, id: null })
    setValue('')
    if (inputRef.current) inputRef.current.value = ''
  }

  const fetchULIPossibleErrorsOpenScheduling = tagsArr => {
    appointmentService
      .getUniqueIndentifiersErrorsOpenScheduling({
        orders: tagsArr.map(tag => tag.identifier),
        subdomain: subdomain === 'localhost' ? DEFAULT_SHIPPER_SUBDOMAIN : subdomain,
        facilityId
      })
      .then(([result]) => {
        result ? setOrderValidation(result) : setOrderValidation(defaultValidationOrder)
        setUniqueLoadIdentifierError(!result.canProceed, direction)
      })
  }

  // fetch ULI possible errors
  const fetchULIPossibleErrors = tagsArr => {
    appointmentService
      .getUniqueIndentifiersErrors({
        orders: tagsArr.map(tag => tag.identifier),
        facilityId: facilityId,
        schedulerId:
          currentUser?.userType == CARRIER ? appointment?.schedulerId : currentUser?.schedulerId,
        userId: currentUser?.id
      })
      .then(([result]) => {
        result ? setOrderValidation(result) : setOrderValidation(defaultValidationOrder)
        setUniqueLoadIdentifierError(!result.canProceed, direction)
      })
  }

  React.useEffect(() => {
    if (appointment.purchaseOrdersAttributes && appointment.purchaseOrdersAttributes.length > 0) {
      setTags(appointment.purchaseOrdersAttributes)
    }
  }, [appointment.purchaseOrdersAttributes, setTags])

  React.useEffect(() => {
    if (tags?.length > 0) {
      validateUserType(tags)
    }
  }, [appointment.schedulerId])

  const showOrderDetails = () =>
    orderValidation.orders?.length > 0 &&
    (facility?.appointmentPreference?.showQuantityOnAppointmentCreation ||
      facility?.appointmentPreference?.showWeightOnAppointmentCreation ||
      facility?.appointmentPreference?.showDeliveryInstructionsOnAppointmentCreation)

  return (
    <FormControl>
      <>
        <VInput
          aria-label="UniqueLoadIdentifier"
          ref={inputRef}
          value={value}
          label={!value.length ? `* ${inputLabel}` : 'Press ⮐ to add'}
          tags={tags}
          onChange={e => setValue(e.currentTarget.value)}
          overrides={{
            Input: {
              style: { width: 'auto', flexGrow: 1 },
              component: InputReplacement as any,
              props: {
                tags: tags,
                removeTag: removeTag,
                onKeyDown: handleKeyDown,
                onBlur: handleBlour
              }
            }
          }}
        />
        <Block marginLeft="scale800">
          {orderValidation?.errors?.length > 0 && (
            <>
              <MonoLabelXSmall color="warning" marginTop="scale400">
                {t('Scheduler.OpenScheduling.Steps.Fields.PurchaseOrder.Validations.Header.Text')}
              </MonoLabelXSmall>
              {orderValidation.errors.map(error => (
                <MonoLabelXSmall color="warning" key={error.message}>
                  {error.message}
                </MonoLabelXSmall>
              ))}
            </>
          )}
        </Block>
        <Block marginLeft="scale800" marginTop="scale800">
          {showOrderDetails() && (
            <OrdersDetails
              orders={orderValidation.orders}
              totals={orderValidation.totals}
              appointmentPreference={facility?.appointmentPreference}
            />
          )}
        </Block>
      </>
    </FormControl>
  )
}

export default UniqueIdentifiers
