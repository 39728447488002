import React, { useEffect, useState } from 'react'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'

import Checkbox from 'components/ui/generic/Checkbox'
import StyledSpinner from 'components/shared/styled-spinner'
import authenticatedFetch from '../../utils/authenticated-fetch'
import { useTranslation } from 'react-i18next'
import { LabelXSmall } from 'baseui/typography'
import { FacilitySelectProps } from './facilities-select.styled'

const FacilitiesSelect = ({ appointmentType, setAppointmentType }) => {
  const facilityIds = (appointmentType && appointmentType.appointmentTypesFacilities) || []
  const [facilities, setFacilities] = useState<any[]>([])
  const [loadingFacilities, setLoadingFacilities] = useState<boolean>(false)
  const { t } = useTranslation()

  useEffect(() => {
    setLoadingFacilities(true)
    authenticatedFetch({ path: `/facilities.json` })
      .then(([json, status]) => {
        const selectedFacilities = json.map(facility => {
          const { id } = facility
          const state = facilityIds.filter(item => item.facilityId == id)[0]
          return {
            ...facility,
            facilityId: facility.id,
            checked: !!state,
            verifyApprovalNewAppointment: state?.verifyApprovalNewAppointment,
            verifyApprovalRescheduleAppointment: state?.verifyApprovalRescheduleAppointment
          }
        })
        setFacilities(selectedFacilities)
      })
      .finally(() => setLoadingFacilities(false))
  }, [])

  const setValue = (index, attribute, value) => {
    if (attribute) {
      const newFacilities = [...facilities]
      facilities[index][attribute] = value
      setFacilities(newFacilities)
      const facilityIds = facilities
        .filter(facility => {
          return facility.checked
        })
        .map(facility => ({
          facilityId: facility.facilityId,
          verifyApprovalNewAppointment: facility.verifyApprovalNewAppointment,
          verifyApprovalRescheduleAppointment: facility.verifyApprovalRescheduleAppointment
        }))
      setAppointmentType({
        ...appointmentType,
        appointmentTypesFacilities: facilityIds
      })
    }
  }

  return (
    <>
      <FlexGrid flexGridColumnCount={[2]} flexGridColumnGap="scale200" marginBottom="scale600">
        <FlexGridItem overflow="hidden">
          <LabelXSmall>{t('Settings.AppointmentTypes.Details.Facility')}</LabelXSmall>
        </FlexGridItem>
        <FlexGridItem overflow="hidden">
          <LabelXSmall>{t('Settings.AppointmentTypes.Details.ApprovalWorkflow.Label')}</LabelXSmall>
        </FlexGridItem>
      </FlexGrid>
      <FlexGrid
        flexGridColumnCount={[1]}
        flexGridColumnGap="scale200"
        flexGridRowGap="scale200"
        marginBottom="scale600">
        {loadingFacilities ? (
          <StyledSpinner />
        ) : (
          facilities.map((facility, index) => {
            return (
              <>
                <FlexGridItem key={index} overflow="hidden">
                  <FlexGrid
                    flexGridColumnCount={[3]}
                    flexGridColumnGap="scale200"
                    flexGridRowGap="scale200">
                    <FlexGridItem overflow="hidden">
                      <Checkbox
                        checked={facility.checked}
                        onChange={e => {
                          setValue(index, 'checked', e.currentTarget.checked)
                        }}
                        label={facility.name}
                      />
                    </FlexGridItem>
                    <FlexGridItem overflow="hidden">
                      <Checkbox
                        disabled={!facility.checked}
                        checked={facility.verifyApprovalNewAppointment}
                        onChange={e => {
                          setValue(index, 'verifyApprovalNewAppointment', e.currentTarget.checked)
                        }}
                        label={t(
                          'Settings.AppointmentTypes.Details.ApprovalWorkflow.NewAppointment'
                        )}
                        {...FacilitySelectProps}
                      />
                    </FlexGridItem>
                    <FlexGridItem overflow="hidden">
                      <Checkbox
                        disabled={!facility.checked || facility.verifyApprovalNewAppointment}
                        checked={
                          facility.verifyApprovalRescheduleAppointment ||
                          facility.verifyApprovalNewAppointment
                        }
                        onChange={e => {
                          setValue(
                            index,
                            'verifyApprovalRescheduleAppointment',
                            e.currentTarget.checked
                          )
                        }}
                        label={t(
                          'Settings.AppointmentTypes.Details.ApprovalWorkflow.AppointmentReschedule'
                        )}
                        {...FacilitySelectProps}
                      />
                    </FlexGridItem>
                  </FlexGrid>
                </FlexGridItem>
              </>
            )
          })
        )}
      </FlexGrid>
    </>
  )
}

export default FacilitiesSelect
